import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { getDedicatedTrailersByLocationID, addDedicatedTrailer, saveDedicatedTrailer,
  removeDedicatedTrailer} from "../../services/locationService";
import {getAllCommodityGroups, getAllTrailerByCommodityGroupId} from "../../services/commoditygroupService";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import errorMessageDisplay from "../../utils/errorMessageDisplay";
import { Formik, Form } from "formik";
import * as yup from "yup";

import ProgressBar from "react-bootstrap/ProgressBar";
import { ContextData } from "../../components/appsession";
import { DateTime } from "luxon";
// import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";
import { Autocomplete } from "@mui/lab";
import { TextField } from "@mui/material";

import { Grid, GridColumn } from "@progress/kendo-react-grid";

import { process } from "@progress/kendo-data-query";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const LocationDedicatedTrailersBody = (props) => {
  const [userData, setuserData] = useContext(ContextData);
  const { locationById, accessDisabled } = props;
  // const planners = userData.roles?.map((e) => e.permissionAccess);
  // const commodity = planners[0].filter(
  //   (element) => element.permission === "Locations"
  // );

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });


  const [modalShow, setModalShow] = useState(false);

  const [allCGs, setallCG] = useState([]);
  const [seletedCG, setseletedCG] = useState({});

  const [allDedicatedTrailers, setallDedicatedTrailers] = useState([]);

  const [allTrailers, setallTrailers] = useState([]);
  const [seletedTrailer, setseletedTrailer] = useState({});

  const [shipperPools, setshipperPools] = useState({});
  const [showDeleteModal, setshowDeleteModal] = useState(false);

  const [showEditModal, setshowEditModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const [trailerForEdit, settrailerForEdit] = useState({});
  const [dedicatedTrailerDetails, setdedicatedTrailerDetails] = useState({});

  const [EffectiveDate, setEffectiveDate] = useState(null);
  const [ExpiryDate, setExpiryDate] = useState(null);

  const [dataResult, setDataResult] = useState(
    process(allDedicatedTrailers, dataState)
  );

  const currentDate = new Date();

  const currentDayOfMonth = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const dateString =
    currentYear +
    "-" +
    ("0" + (currentMonth + 1)).slice(-2) +
    "-" +
    ("0" + currentDayOfMonth).slice(-2);

  const searchHandler = async (e) => {
    await getallDedicatedTrailer();
  };

  const dataStateChange = (event) => {
    setDataResult(process(allDedicatedTrailers, event.dataState));
    setDataState(event.dataState);
  };

  useEffect(() => {
    setDataResult(process(allDedicatedTrailers, dataState));
  }, [allDedicatedTrailers]);

  const handleDateChangeEffectiveDate = (date) => {
    setEffectiveDate(date);
  };
  const handleDateChangeExpiryDate = (date) => {
    setExpiryDate(date);
  };

  const addDedicatedTrailers = async () => {
   
    const effectivedate = DateTime.fromISO(EffectiveDate).toMillis();
    const expirationdate = DateTime.fromISO(ExpiryDate).toMillis()
   
    if(effectivedate > expirationdate){
      return toast.error("Expiration Date can't be before Effective Date", "Error");
    }
    if (
      !seletedCG.code ||
      !dedicatedTrailerDetails.locationid ||
      !dedicatedTrailerDetails.trailerid ||
      !effectivedate
    ) {
      return toast.error("Please fill all required fields", "Error");
    }
    const payload = {
      commodityid: seletedCG.code,
      locationid: dedicatedTrailerDetails.locationid,
      trailerid: dedicatedTrailerDetails.trailerid,
      effectivedate: effectivedate,
      expirationdate: expirationdate,
    };
    try {
      const dts = await addDedicatedTrailer(payload);
      const allDTs = await getDedicatedTrailersByLocationID(
        locationById.id.toString()
      );
     
      if (dts.length > 0) {
        let newDataValue = [];
        for (let index = 0; index < allDTs.length; index++) {
          const temp = allDTs[index];
          temp.effective_date_utc = DateTime.fromMillis(
            parseInt(temp.effective_date_utc * 1000)
          )
            .toFormat("MM-dd-yyyy")
            .toString();
          if (temp.expiration_date_utc === 0) {
            temp.expiration_date_utc = "";
          } else {
            temp.expiration_date_utc = DateTime.fromMillis(
              parseInt(temp.expiration_date_utc * 1000)
            )
              .toFormat("MM-dd-yyyy")
              .toString();
          }
          newDataValue.push(temp);
        }
        setallDedicatedTrailers(newDataValue);
        props.parentCallBackFromLocationBodyForDedicatedTrailer(newDataValue);
       
        setModalShow(false);
        setEffectiveDate(null);
        setExpiryDate(null);
      }
      return toast.success(
        "Dedicated Trailer is  Added",
        "success"
      );
    } catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('This trailer has already been dedicated for these dates', { autoClose: false } );
      }
    }
  };

  const initalValue = {
    commodity: "",
    effDate: "",
    expDate: "",
    trailer: "",
  };
  const initalValueForEdit = {
    commodity: trailerForEdit.CommodityGroup ?? "",
    effDate: trailerForEdit.EffectiveDate ?? null,
    expDate: trailerForEdit.expiration_date ?? null,
    trailer: trailerForEdit.trailerid ?? "",
  };
  const validation = yup.object().shape({
    effDate: yup.date().nullable().required("Effective date is required"),
    expDate: yup
      .date()
      .min(yup.ref("effDate"), "Expiration date can't be before Effective date")
      .required("Expiration date is required"),
  });

  const saveDedicatedTrailers = async () => {
    // let effective = new Date(trailerForEdit.effective_date_utc).getTime();
    
    const effectivedate = DateTime.fromISO(EffectiveDate).toMillis();
    const expirationdate = DateTime.fromISO(ExpiryDate).toMillis()
   

  
      if(effectivedate > expirationdate){
        return toast.error("Expiration Date can't be before Effective Date", "Error");
      }
    let payload = {
      id: trailerForEdit.dedicatedtrailer_id,
      trailerid: trailerForEdit.trailer_id,
      effectivedate: effectivedate,
      expirationdate: expirationdate,
    };
    try {
      const dts = await saveDedicatedTrailer(payload);
      if (dts.length > 0) {
        const allDTs = await getDedicatedTrailersByLocationID(
          locationById.id.toString()
        );
        let newDataValue = [];
        for (let index = 0; index < allDTs.length; index++) {
          const temp = allDTs[index];
          temp.effective_date_utc = DateTime.fromMillis(
            parseInt(temp.effective_date_utc * 1000)
          )
            .toFormat("MM-dd-yyyy")
            .toString();
          if (temp.expiration_date_utc === 0) {
            temp.expiration_date_utc = "";
          } else {
            temp.expiration_date_utc = DateTime.fromMillis(
              parseInt(temp.expiration_date_utc * 1000)
            )
              .toFormat("MM-dd-yyyy")
              .toString();
          }
          newDataValue.push(temp);
        }
        setallDedicatedTrailers(newDataValue);
        props.parentCallBackFromLocationBodyForDedicatedTrailer(newDataValue);
        setshowEditModal(false);
       
      }
      return toast.success(
        "Dedicated Trailer  Updated",
        "success"
      );
    } catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Dedicated Trailer not Updated, Please Refresh Your Screen', { autoClose: false } );
      }
    }
  };

  const removeDedicatedTrailerFunction = async () => {
    try {
      let payload = {
        id: trailerForEdit.dedicatedtrailer_id,
      };
      const removeRes = await removeDedicatedTrailer(payload);
      if (removeRes === 1) {
        const allDTs = await getDedicatedTrailersByLocationID(
          locationById.id.toString()
        );
        let newDataValue = [];
        for (let index = 0; index < allDTs.length; index++) {
          const temp = allDTs[index];
          temp.effective_date_utc = DateTime.fromMillis(
            parseInt(temp.effective_date_utc * 1000)
          )
            .toFormat("MM-dd-yyyy")
            .toString();
          if (temp.expiration_date_utc === 0) {
            temp.expiration_date_utc = "";
          } else {
            temp.expiration_date_utc = DateTime.fromMillis(
              parseInt(temp.expiration_date_utc * 1000 || "NO DATA")
            )
              .toFormat("MM-dd-yyyy")
              .toString();
          }
          newDataValue.push(temp);
        }
        setallDedicatedTrailers(newDataValue);
        props.parentCallBackFromLocationBodyForDedicatedTrailer(newDataValue);
        setshowDeleteModal(false);     
      }
      
      return toast.success(
        "Dedicated Trailer is  deleted",
        "success"
      );
    
    } catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Dedicated Trailer is not deleted, Please Refresh Your Screen', { autoClose: false } );
      }
    }
  };

  const getallDedicatedTrailer = async () => {
    try {
      const allDTs = await getDedicatedTrailersByLocationID(
        locationById.id.toString()
      );
      if (allDTs.length > 0) {
        let newDataValue = [];
        for (let index = 0; index < allDTs.length; index++) {
          const temp = allDTs[index];
          temp.effective_date_utc = DateTime.fromMillis(
            parseInt(temp.effective_date_utc * 1000)
          )
            .toFormat("MM-dd-yyyy")
            .toString();
          if (temp.expiration_date_utc === 0) {
            temp.expiration_date_utc = "";
          } else {
            temp.expiration_date_utc = DateTime.fromMillis(
              parseInt(temp.expiration_date_utc * 1000)
            )
              .toFormat("MM-dd-yyyy")
              .toString();
          }
          newDataValue.push(temp);
        }
        setallDedicatedTrailers(newDataValue);
        props.parentCallBackFromLocationBodyForDedicatedTrailer(newDataValue);
      }
    } catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('No Dedicated Trailer Found', { autoClose: false } );
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    getallDedicatedTrailer();
    getAllCGs();
    if (modalShow) {
      setEffectiveDate(null);
      setExpiryDate(null);
    }
    setLoading(false);
  }, [modalShow]);

  const getAllCGs = async () => {
    const allCgs = await getAllCommodityGroups();

    setallCG(allCgs);
  };

  const getAllTrailers = async (cgId) => {
    const alltrailers = await getAllTrailerByCommodityGroupId(
      cgId,
      ""
    );

    setallTrailers(alltrailers);
  };

  const getAllShipperPools = async (cgId) => {
    const shippers = locationById?.shipperpoolid?.filter((it) => it.cg === cgId);

    setshipperPools(shippers);
  };

  const commodityGroupIdHandler = (e, value) => {
    if (value) {
      setseletedCG(value);
      setdedicatedTrailerDetails((item) => ({
        ...item,
        locationid: locationById.id.toString(),
      }));
      getAllTrailers(value.code).then(function () { });
    }
  };

  const trailerIdIdHandler = (e, value) => {
    if (value) {
      setseletedTrailer(value);
      setdedicatedTrailerDetails((item) => ({
        ...item,
        trailerid: value.trailer_id,
      }));
    }
  };

  const editDedicatedTrailers = (trailer) => {
    
    const effective_date_utc = DateTime.fromFormat(trailer.effective_date_utc, 'MM-dd-yyyy');
    const expiration_date_utc=DateTime.fromFormat(trailer.expiration_date_utc, 'MM-dd-yyyy');
    getAllShipperPools(trailer?.CommodityGroup);
    setExpiryDate(expiration_date_utc);
    setEffectiveDate(effective_date_utc)
    settrailerForEdit(trailer);
    setshowEditModal(true);
  };

  const commodityGroupIdHandlerForEdit = (e, value) => {
    if (value.length > 0) {
      settrailerForEdit((item) => ({
        ...item,
        cgId: value.code,
        locationid: locationById.id.toString(),
      }));

      getAllTrailers(value.code);
      getAllShipperPools(value.code).then(function () { });
    } else {
      settrailerForEdit((item) => ({
        ...item,
        cgId: "",
      }));
      const shippers = locationById.shipperpoolid;
      setshipperPools(shippers);
    }
  };

  const trailerIdIdHandlerEdit = (e, value) => {
    settrailerForEdit((item) => ({
      ...item,
      trailerid: value.id,
    }));
  };

  function handleDateChangeEffectiveDateForEditChange(value) {
    // settrailerForEdit((prevState) => ({
    //   ...prevState,
    //   effective_date_utc: value,
    // }));
    setEffectiveDate(value)
  }

  function handleDateChangeExpiryDateForEditChange(value) {
    // settrailerForEdit((prevState) => ({
    //   ...prevState,
    //   expiration_date_utc: value,
    // }));
    setExpiryDate(value)
  }

  function handleShowModalclose() {
    setModalShow(false);
    setEffectiveDate(null);
    setExpiryDate(null);
  }

  function openDeleteTrailerModal(trailer) {
    settrailerForEdit(trailer);
    setshowDeleteModal(true);
  }

  const Editdeletededicatedtrailers = (props) => {
    return (
      <td className="adjustbutton">
        <button
            type="button"
          className="btn_blue_smadjust btn-blue ml_10"
            onClick={() => props.editDedicatedTrailers(props?.dataItem)}
            disabled={accessDisabled ? true : false}
            // style={{background: accessDisabled ? disabled : ""}}
          >
          <i className="fa fa-pencil mr_5 del_icon" aria-hidden="true"></i>
            EDIT
          </button>
          <button
            type="button"
          className="btn_blue_smadjust btn-blue ml_10"
            onClick={() => props.openDeleteTrailerModal(props.dataItem)}
            disabled={accessDisabled ? true : false}
            // style={{background: accessDisabled ? disabled : ""}}
          >
          <i className="fa fa fa-trash mr_5 del_icon" aria-hidden="true"></i>
            DELETE
          </button>
      </td>
    );
  };

  const EditDeleteDedicatedTrailer = (props) => (
    <Editdeletededicatedtrailers
      {...props}
      openDeleteTrailerModal={openDeleteTrailerModal}
      editDedicatedTrailers={editDedicatedTrailers}
    />
  );

  return (
    <>
      <ToastContainer position="top-right" theme="colored" />
      <div className="row mt_30">
        <div className="col-xl-12">
          <div className="card card_shadow">
            <div className="card-body">
              <div className="table-responsive">
                <div className="button-right">
                  <div>
                    <h2 className="header-title1">
                      Total Dedicated Trailers: {allDedicatedTrailers?.length}
                    </h2>
                  </div>
                  <div className="addbutton">
                    <button
                        type="button"
                        className="btn_blue btn-blue mr_10 mb-20 "
                        onClick={() => setModalShow(true)}
                        disabled={accessDisabled ? true : false}
                      >
                        ADD
                      </button>
                  </div>
                </div>
                {allDedicatedTrailers?.length > 0 ? (
                  <Grid
                    filter={dataState.filter}
                    filterable={true}
                    sort={dataState.sort}
                    sortable={true}
                    pageable={{
                      pageSizes: [5, 10, 20, 25, 50, 100],
                      info: true,
                      previousNext: true,
                      buttonCount: 10,
                    }}
                    resizable={true}
                    skip={dataState.skip}
                    take={dataState.take}
                    data={dataResult}
                    onDataStateChange={dataStateChange}
                    onRowClick={(e) => props.parentcallback(true, e.dataItem)}
                  >
                    <GridColumn
                      title="Action"
                      width="200px"
                      cell={EditDeleteDedicatedTrailer}
                      filterable={false}
                    />
                    <GridColumn
                      field="trailer_id"
                      title="Trailer Id"
                      width="200px"
                    />
                    <GridColumn
                      field="commodity_group"
                      title="Commodity Group"
                      width="200px"
                    />
                    <GridColumn
                      field="effective_date_utc"
                      title="Effective Date"
                      width="200px"
                    />
                    <GridColumn
                      field="expiration_date_utc"
                      title="Expiration Date"
                      width="200px"
                    />
                    <GridColumn
                      field="is_active"
                      sortable={true}
                      cell={(e) => {
                        return (
                          <td
                            style={{
                              color: e.dataItem.is_active
                                ? "#259125"
                                : "#FF0000",
                            }}
                          >
                            {e.dataItem.is_active ? "True" : "False"}
                          </td>
                        );
                      }}
                      title="Is Active"
                      width="150px"
                      filterable={true}
                      filter={"boolean"}
                    />
                    <GridColumn
                      field="status"
                      title="Status"
                      width="200px"
                      filterable={true}
                      cell={(e) => {
                        return (
                          <td>
                            {e.dataItem.status == "A" ? "Active" : "Inactive"}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field="make"
                      title="Make"
                      width="200px"
                      cell={(e) => {
                        return (
                          <td>
                            {e.dataItem.make ? e.dataItem.make : ""}
                          </td>
                        );
                      }}
                    />
                    <GridColumn field="model" title="Model" width="200px" />
                    <GridColumn
                      field="license_plate"
                      title="License Plate"
                      width="200px"
                      cell={(e) => {
                        return (
                          <td>
                            {e.dataItem.license_plate
                              ? e.dataItem.license_plate
                              : ""}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field="driver_side_tag"
                      title="QR code"
                      width="200px"
                      cell={(e) => {
                        return (
                          <td>
                            {e.dataItem.driver_side_tag
                              ? e.dataItem.driver_side_tag
                              : ""}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field="terminal_full_name"
                      title="Terminal"
                      width="200px"
                    />
                    <GridColumn field="region" title="Region" width="200px" />
                    <GridColumn
                      field="pm_due_date_utc"
                      title="Next PM Date"
                      width="200px"
                      cell={(e) => {
                        return (
                          <td>
                            {e.dataItem.pm_due_date_utc == 0
                              ? ""
                              : e.dataItem.pm_due_date_utc}
                          </td>
                        );
                      }}
                    />
                  </Grid>
                ) : loading ? (
                  <div>
                    <ProgressBar animated now={100} />
                    <div className="middle loader--text1"> </div>
                  </div>
                ) : (
                  <div>No data found</div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Modal For Add */}

        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              ADD DEDICATED TRAILER
            </Modal.Title>
          </Modal.Header>
          <Formik
            initialValues={initalValue}
            validationSchema={validation}
            enableReinitialize={true}
          >
            {({ values, handleChange, handleBlur }) => (
              <>
                <Modal.Body>
                  <div className="form-group">
                    <label for="typeHeadCommodityGroup">
                      Commodity Group *
                    </label>
                    <div className="meterial_autocomplete">
                      <Autocomplete
                        id="combo-box-demo"
                        options={allCGs}
                        getOptionLabel={(option) =>
                          `${option.code} : ${option.description}`
                        }
                        onChange={commodityGroupIdHandler}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Choose a Commodity Group..."
                            variant="outlined"
                          />
                        )}
                      />
                    </div>

                    <label for="typeHeadTrailerId">Trailer ID *</label>
                    <div className="meterial_autocomplete">
                      <Autocomplete
                        id="combo-box-demo"
                        options={allTrailers}
                        getOptionLabel={(option) =>
                          `${option.trailer_id} - ${option.eqmodel}`
                        }
                        onChange={trailerIdIdHandler}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Choose a Trailer..."
                            variant="outlined"
                          />
                        )}
                      />
                    </div>

                    <label for="txtEffectiveDate">Effective Date *</label>
                    <div className="meterial_autocomplete">
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                          disableToolbar
                          format="MM-dd-yyyy"
                          id="date-picker-inline"
                          fullWidth="true"
                         
                          disablePast
                          inputVariant="outlined"
                          value={EffectiveDate}
                          error={false}
                          helperText={null}
                          placeholder="MM-dd-yyyy"
                          onChange={handleDateChangeEffectiveDate}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                    <label for="txtExpiryDate">Expiration Date</label>
                    <div className="meterial_autocomplete">
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                          disableToolbar
                          format="MM-dd-yyyy"
                          id="date-picker-inline"
                          fullWidth="true"
                          inputVariant="outlined"
                          value={ExpiryDate}  
                          error={false}
                          helperText={null}
                          placeholder="MM-dd-yyyy"
                          onChange={handleDateChangeExpiryDate}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={handleShowModalclose}>Close</Button>
                  <Button type="submit" onClick={addDedicatedTrailers}>
                    Save
                  </Button>
                </Modal.Footer>
              </>
            )}
          </Formik>
        </Modal>

        {/* Modal For Edit */}
        <Modal
          show={showEditModal}
          onHide={() => setshowEditModal(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              EDIT DEDICATED TRAILER
            </Modal.Title>
          </Modal.Header>
          <Formik
            initialValues={initalValueForEdit}
            enableReinitialize={true}
          >
            {({ values, handleChange, handleBlur }) => (
              <Form>
                <Modal.Body>
                  <div className="form-group">
                    <label for="typeHeadCommodityGroup">
                      Commodity Group *
                    </label>
                    <div className="meterial_autocomplete">
                      <Autocomplete
                        id="combo-box-demo"
                        options={allCGs}
                        getOptionLabel={(option) =>
                          `${option.code} : ${option.description}`
                        }
                        defaultValue={{
                          code:
                            trailerForEdit.commodity_group === ""
                              ? trailerForEdit.commodity_group
                              : trailerForEdit.commodity_group,
                          description:
                            trailerForEdit.commodity_description === ""
                              ? trailerForEdit.commodity_description
                              : trailerForEdit.commodity_description,
                        }}
                        disabled={true}
                        getOptionSelected={(option) => option.code}
                        onChange={commodityGroupIdHandlerForEdit}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Choose a Commodity Group..."
                            variant="outlined"
                          />
                        )}
                      />
                    </div>

                    <label for="typeHeadTrailerId">Trailer ID *</label>
                    <div className="meterial_autocomplete">
                      <Autocomplete
                        id="combo-box-demo"
                        options={allTrailers}
                        getOptionLabel={(option) =>
                          `${option.trailer_id} - ${option.eqmodel}`
                        }
                        onChange={trailerIdIdHandlerEdit}
                        disabled={true}
                        defaultValue={{
                          trailer_id: trailerForEdit.trailer_id,
                          eqmodel: trailerForEdit.model,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Choose a Trailer..."
                            variant="outlined"
                          />
                        )}
                      />
                    </div>

                    <label for="txtEffectiveDate">Effective Date *</label>
                    <div className="meterial_autocomplete">
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                          disableToolbar
                          format="MM-dd-yyyy"
                          id="date-picker-inline"
                          fullWidth="true"
                          error={false}
                          helperText={null}
                          // minDate={new Date()}
                          inputVariant="outlined"
                          variant="outlined"
                          disabled={true}
                          value={EffectiveDate}
                          placeholder="MM-dd-yyyy"
                          onChange={handleDateChangeEffectiveDateForEditChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </LocalizationProvider>
                    </div>

                    <label for="txtExpiryDate">Expiration Date </label>
                    <div className="meterial_autocomplete">
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                          disableToolbar
                          error={false}
                          helperText={null}
                          format="MM-dd-yyyy"
                          id="date-picker-inline"
                          fullWidth="true"
                          variant="outlined"
                          inputVariant="outlined"
                          value={ExpiryDate}
                          // minDate={trailerForEdit.effective_date_utc}
                          placeholder="MM-dd-yyyy"
                          onChange={handleDateChangeExpiryDateForEditChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={(e) => setshowEditModal(false)}>
                    Close
                  </Button>
                  <Button type="submit" onClick={saveDedicatedTrailers}>
                    Save
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>

        {/* modal for Delete */}

        <Modal
          show={showDeleteModal}
          onHide={() => setshowDeleteModal(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete Dedicated Trailer
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <p>
                Do you want to Delete the Trailer Id {trailerForEdit.trailer_id}{" "}
                ?
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={(e) => setshowDeleteModal(false)}>Close</Button>
            <Button onClick={(e) => removeDedicatedTrailerFunction(e)}>Delete</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default LocationDedicatedTrailersBody;
