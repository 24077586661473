
import React from 'react';
import { toast } from 'react-toastify'; 

const errorMessageDisplay = (arr) => {

  const errormessageTable = (arr) => {
    return <div className="ml_30">
      {
        arr?.map((el) => {
          return <div style={{ marginBottom: "15px" }}>
            <div style={{ padding: "7px", fontSize: "16px" }}>{el?.message}</div>
            {
              el?.data &&
              <table className="continuity_error_table">
                <tr>
                  {
                    Object.keys(el?.data)?.map((data) => <th>{data}</th>)
                  }
                </tr>
                <tr>
                  {
                    Object.values(el?.data)?.map((data) => <td>{/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/.test(data?.split("T")[0] ?? "") ? DateTime.fromISO(data).setZone(plannerTimezone).toFormat("MM-dd-yyyy HH:mm ZZZZ").toString() : data}</td>)
                  }
                </tr>
              </table>
            }
          </div>
        })
      }
    </div>
  }

    let divelemnt
    if (arr?.length > 0 && arr?.map((el) => el.message)?.join(",")?.split(" ")?.length > 15) {
      divelemnt = errormessageTable(arr)
      toast.error(divelemnt, { autoClose: false, position: "top-center" });
    } else if (arr?.length > 0) {
      if (arr?.filter((el) => el?.data && el?.data !== undefined)?.length > 0) {
        divelemnt = errormessageTable(arr)
        toast.error(divelemnt, { autoClose: false, position: "top-center" });
      } else {
        toast.error(`${arr?.map((el) => el.message)?.join(",")}`, { autoClose: false });
      }
    } else {
      console.log(`errorMessageDisplay.js receieved array length as 0`);
      toast.error('Something went wrong', { autoClose: false });
    }
};


export default errorMessageDisplay;
