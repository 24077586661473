import {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import TextField from "@mui/material/TextField";
import { Picky } from "react-picky";
import Washprocedure_table from "../tankwashcodetable/washprocedure_table";
import { ToastContainer, toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import {getAllWashcodes, updateTankWashCode} from "../../services/tankWashService";
import { Autocomplete } from "@mui/lab";
import errorMessageDisplay from '../../utils/errorMessageDisplay';
const EditTankWashModel = (props) => {
    const {
        descriptionsOptions, 
        selecteddescriptionOptions, 
        setModalShowEdit, 
        modalShowEdit, 
        editTankWash, 
        setEditTankWash,
        washprocedureData,
        setSelecteddescriptionOptions,
        setwashprocedureData,
        settankwashcode,
        procedureSequence,
        procedureSequenceForEdit
    } = props;
    
  const [dataForIsactive, setdataForIsactive] = useState([
    {
      is_active: true, value: "Yes"
    },
    {
      is_active: false, value: "No"
    },
  ]);
  const [dataForType, setdataForType] = useState([
    {
        type: "I",value:"Interior"
    },
    {
        type: "E",value:"Exterior"
    },
  ]);
    const capturewashcodeEdit = (value) => {
        if(value != null){
            setEditTankWash((prev) => ({
                ...prev,
                wash_code: value
            }))
        } else{
          setEditTankWash((prev) => ({
            ...prev,
            wash_code: null
        }))
        }
    }
    const descriptionChangeHandlerEdit = (e) => {
        setEditTankWash((prev) => ({
            ...prev,
            description: e.target.value
        }))
    }
    // const typeChangeHandlerEdit = (value) => {
    //     if(value != null){
    //         setEditTankWash((prev) => ({
    //             ...prev,
    //             type: value
    //         }))
    //     } else{
    //       setEditTankWash((prev) => ({
    //         ...prev,
    //         type: null
    //     }))
    //     }
    // }

    const isactiveChangeHandlerEdit = (e,value) => {
      if(value != null){
        setEditTankWash((prevState) => ({
              ...prevState,
              is_active: value.is_active,
            }));
      } else {
        setEditTankWash((prevState) => ({
          ...prevState,
          is_active: null,
        }));

      }
    }
     

    const typeChangeHandlerEdit = (e,value) => {
      if(value != null){
        setEditTankWash((prevState) => ({
              ...prevState,
              type: value.type,
            }));
      } else {
        setEditTankWash((prevState) => ({
          ...prevState,
          type: null,
        }));
      }
     
    };
    const captureDurationEdit = (value) => {
        if(value != null){
            setEditTankWash((prev) => ({
                ...prev,
                minutes: value
            }))
        } else{
          setEditTankWash((prev) => ({
            ...prev,
            minutes: null
        }))
        }
      }
    const selectChangeHandlerEdit = async (data) => {
      setSelecteddescriptionOptions(data)
      setwashprocedureData(data)
    }

    const editTankprocedureHandler = async () => {

            if(
                !editTankWash.wash_code || 
                !editTankWash.description || 
                !editTankWash.type
              ){
              return toast.error(
                `Please fill all the fields`,
                "Error"
              );
            } else{
                try{
                    editTankWash.wash_type_procedure = procedureSequenceForEdit
                    const updateWashCode = await updateTankWashCode(editTankWash);
                    if(updateWashCode){
                        setEditTankWash(editTankWash)
                        setModalShowEdit(false)
                        const allWashCode = await getAllWashcodes()
                        settankwashcode(allWashCode)
                        return toast.success(
                            "Tank wash types edited succesfully",
                            "success"
                            );
                    }
                }catch(err){
                  console.log(err)
                  if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
                    errorMessageDisplay(err?.response?.data?.errors);
                  }else{
                  toast.error('Failed to update tank wsh types', { autoClose: false } );
                  }
                }
            }
    }

    const handleAmountChange = (value) => {
      setEditTankWash(prev => ({
        ...prev,
        amount: value !== null ? parseFloat(value) : null
      }));
    };     

    return(
        <Modal
        show={modalShowEdit}
        onHide={() => setModalShowEdit(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Wash Type
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt_10">
            <label for="tankwashcode">
              Wash Code
            </label>
            <div className="meterial_autocomplete">
                {/* {editTankWash.wash_code &&  */}
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                name="code"
                placeholder="Enter Wash Code..."
                size="small"
                defaultValue={editTankWash.wash_code}
                onChange={(e) => capturewashcodeEdit(e.target.value)}
              />
              {/* } */}
            </div>
          </div>
          <div className="mt_10">
            <label for="exampleAddFormControlTextarea1">Description</label>
            <textarea
              className="form-control"
              name="description"
              placeholder="Description...."
              id="exampleAddFormControlTextarea1"
              rows="3"
              defaultValue={editTankWash.description}
              onChange={(e) => descriptionChangeHandlerEdit(e)}
            />
          </div>
          <div className="meterial_autocomplete">
                <label htmlFor="typeHeadAddStatus">Is Active</label>

                <Autocomplete
                  id="combo-box-demo"
                  options={dataForIsactive}
                  getOptionLabel={(option) => `${option.value}`}
                  defaultValue={dataForIsactive.filter(it=>it.is_active===editTankWash?.is_active)[0]}
                  onChange={isactiveChangeHandlerEdit}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose..."
                      variant="outlined"
                    />
                  )}
                />
                </div>
        
           <div className="meterial_autocomplete">
                <label htmlFor="typeHeadAddStatus">Type</label>

                <Autocomplete
                  id="combo-box-demo"
                  options={dataForType}
                  getOptionLabel={(option) => `${option.value}`}
                  defaultValue={dataForType.filter(it=>it.type===editTankWash?.type)[0]}
                 
             
                  onChange={typeChangeHandlerEdit}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose Type..."
                      variant="outlined"
                    />
                  )}
                />
                </div>
          <label for="tankwashcode">
            Duration (mins)
          </label>
          <div className="meterial_autocomplete">
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              name="duration"
              placeholder="Enter Duration (mins)"
              size="small"
              defaultValue={editTankWash.minutes}
              onChange={(e) => captureDurationEdit(e.target.value)}
            />
          </div>

          <div className="dropdownadjust">
            <div className="mt_10">
              <label for="tankwashcode">Wash Procedure  </label>
              <Picky
                name="procedure"
                options={descriptionsOptions}
                labelKey="label"
                valueKey="value"
                placeholder="Wash Procedure"
                multiple={true}
                includeFilter
                includeSelectAll
                value={selecteddescriptionOptions}
                numberDisplayed={1}
                manySelectedPlaceholder="Wash Procedure : %s "
                allSelectedPlaceholder="Wash Procedure : All"
                onChange={(e, data) => selectChangeHandlerEdit(e, data)}

                renderSelectAll={({
                  filtered,
                  tabIndex,
                  allSelected,
                  toggleSelectAll,
                  multiple,
                }) => {
                  // Don't show if single select or items have been filtered.
                  if (multiple && !filtered) {
                    return (
                      <div className="select_multiple_container">
                        <div
                          tabIndex={tabIndex}
                          role="option"

                          className="option"
                          onClick={toggleSelectAll}
                          onKeyPress={toggleSelectAll}
                        // onChange={handletableopenclose}
                        >
                          <button className="selectall_btn">
                            Select all
                          </button>
                        </div>
                        <div
                          tabIndex={tabIndex}
                          role="option"
                          className="option"
                          onClick={() => {
                            setSelecteddescriptionOptions([])
                            setwashprocedureData([])

                          }}
                          onKeyPress={toggleSelectAll}
                        >
                          <button className="selectall_btn">Clear All</button>
                        </div>
                      </div>

                    );
                  }
                }}
              />
            </div>
          </div>

          <div className='mt_10'>
          <label htmlFor='amount'>Amount</label>
          <div className='meterial_autocomplete'>
            <TextField
              fullWidth
              variant='outlined'
              type='number'
              name='amount'
              placeholder='Enter Amount...'
              size='small'
              defaultValue={editTankWash.amount}
              onChange={(e) => handleAmountChange(e.target.value)}
            />
          </div>
        </div>

          <div className="mt_20">
            {washprocedureData[0] && (
                  <div>
                    <label for='tankwashcode'>
                      Wash procedures : {washprocedureData[0]?.length}
                    </label>


                    <Washprocedure_table
                      washprocedureData={washprocedureData}
                      // setwashprocedureData={setwashprocedureData}
                      procedureSequence={procedureSequence}

                    />
                  </div>
                )}
            <div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => setModalShowEdit(false)}>Close</Button>
          <Button type="submit" onClick={(e) => editTankprocedureHandler(e)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default EditTankWashModel