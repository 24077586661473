import React, { useState, useEffect, useContext } from "react";
import User from "../../assets/images/users/user_Icon.png";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { ContextData } from "../appsession/index";
import { Formik, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import { Tooltip } from "@mui/material";

const Userprofileheader = (props) => {
  const { user } = props;
  const [tabSelected, settabSelected] = useState({
    profile: true,
  });
  
  return (
    
          <div className="row df mt_30">
            <div className="tabs">
              <input
                type="radio"
                name="tabs"
                id="tabone"
                defaultChecked={tabSelected.profile}
                onClick={(e) => tabClickHandler(e, "profile")}
              />
              <label htmlFor="tabone">Profile</label>
              <div className="tab">
                <div className="profile_top">
                  <div className="profile_top_left">
                    <img
                      src={User}
                      alt="contact-img"
                      title="contact-img"
                      className="rounded-circle avatar-sm"
                    />
                    <div>
                      <p className="profile_top_left_text_user">
                        {user?.first_name ? user.first_name : ""} {user?.last_name ? user.last_name : ""}
                      </p>
                    </div>
                  </div>
                  <div className="df">
                    <div className="profile_top_right_user">
                      <Tooltip title={user.is_active ? "Active" : "Inactive"}>
                        <div className={user.is_active ? "online_sign" : "offline_sign"}></div>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className="profile_bottom">
                  <div className="profile_bottom_drescription">
                    <p className="profile_bottom_drescription_heading">
                      Contact
                    </p>
                    <p className="profile_bottom__heading_drescription mt_5 df">
                      <div className="edit_first">Phone:</div>
                      <div className="before_edit">
                       {user.phone_number}
                      </div>
                    </p>
                    <p className="profile_bottom__heading_drescription mt_5 df">
                      <div className="edit_first">Address:</div>
                      <div className="before_edit"> 
                      {user.office_location}
                      </div>
                    </p>
                    <p className="profile_bottom__heading_drescription mt_5 df">
                      <div className="edit_first">E-mail:</div>
                      <div className="before_edit">
                      {user.email}
                      </div>
                    </p>
                  </div>

                  <div className="profile_bottom_drescription">
                    <p className="profile_bottom_drescription_heading">
                      User Information
                    </p>
                    <p className="profile_bottom__heading_drescription mt_5 df">
                      <div className="edit_first">AD_ID:</div>
                      <div>
                        <input
                          type="text"
                          name="adId"
                          className="before_edit"
                          value={user.email ? user.email.split('@')[0] : ''}
                          disabled={true}
                        ></input>
                      </div>
                    </p>
                    <p className="profile_bottom__heading_drescription mt_5 df">
                      <div className="edit_first">Loadmaster ID:</div>
                      <div className="before_edit">
                       
                      </div>
                    </p>
                  </div>
                </div>
              </div>

            </div>
           
          </div>
      
  );
};

export default Userprofileheader;
