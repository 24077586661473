import { Container, Navbar } from "react-bootstrap";
import './header.css';
import '../../assets/css/icons.min.css';
import LeftArrow from "../../assets/images/left-arrow.svg";
import { useMsal } from "@azure/msal-react";
import React, { useState } from 'react';

function Header(props) {
    const [itemSelected, setItemSelected] = useState(window.location.pathname);
    const { userclicked, parentcallback, sethamburgerMenuToggle } = props;
    const { instance } = useMsal();
    const [hamburgerMenuToggle, setHamburgerMenuToggle] = useState(false);

    const arrowClickHandler = () => {
        parentcallback(false);
    };

    const hamburgerMenuClick = () => {
        const newToggleState = !hamburgerMenuToggle;
        setHamburgerMenuToggle(newToggleState);
        sethamburgerMenuToggle(newToggleState);
    };

    return (
        (itemSelected !== "/planner" && itemSelected !== "/" )&& (
            <Navbar expand="lg" variant="light" bg="light" className="navbar-custom">
                <div className="container-fluid">
                    <div className="logo_logout_wrapper">
                        <i className="fa fa-bars yard_ham" aria-hidden="true" onClick={hamburgerMenuClick}></i>
                        <div className="logo-box df">
                            <img
                                src={LeftArrow}
                                className={userclicked ? "back_icon" : "back_icon hide_left_icon"}
                                onClick={arrowClickHandler}
                                alt="Back Arrow"
                            />
                            <p className="logo_font">BULKMATIC TRANSPORT</p>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </Navbar>
        )
        
    );
}

export default Header;
