import { useEffect, useState, useContext } from "react";
import {updateAssigneddriversforplanningprofile} from "../../services/userService";

import Userfiltertable from "../../components/userfiltertable/userfiltertable";

import { ContextData } from "../../components/appsession";
import {getAssigneddriversbyplanningprofile} from "../../services/terminalService";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications"; 
import {getTrailersByTerminalId} from "../../services/trailerService";


import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { makeStyles } from "@mui/styles";
import ProgressBar from "react-bootstrap/ProgressBar";
import errorMessageDisplay from "../../utils/errorMessageDisplay";
import { toast } from "react-toastify";

const Planningtab = (props) => {
  const { profileById } = props
  const [userData, setuserData] = useContext(ContextData);
  const [assignedDriversList, setassignedDriversList] = useState([])
  const [assignTrailerList, setassignTrailerList] = useState([]);
  const [isDefaultPlannerChecked, setIsDefaultPlannerChecked] = useState([]);
  const [assignedDriversToUpdate, setAssignedDriversToUpdate] = useState([])
  const [assigncheckeddriver, setassigncheckeddriver] = useState([])

  const [isLoading, setIsLoading] = useState(false);

  const permission = userData?.roles?.rolespermission.find(planningpermission =>
    planningpermission?.permissiondetail.code === "PlanningProfile")

  const useStyles = makeStyles((theme) => ({
    statuscolor: {
      fontWeight: "bold",
      textAlign: "center !important",
      fontSize: 15,
    },
  }));
  const classes = useStyles();
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [

      ],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });
  /// assign driver kendotable
  const [dataResult, setDataResult] = useState(process(assignedDriversList, dataState));

  const dataStateChange = (event) => {
    setDataResult(process(assignedDriversList, event.dataState));
    setDataState(event.dataState);
  };

  useEffect(() => {
    setDataResult(process(assignedDriversList, dataState));
  }, [assignedDriversList]);

  const parentcallback = (data) => {
    setassigncheckeddriver(data)
  }

  const fetchData =async () => {
    setIsLoading(true);
    const assigndriver_planningprofile = await getAssigneddriversbyplanningprofile(
      profileById.id

    );
    setassignedDriversList(assigndriver_planningprofile)
    let allcheckedarr = assigndriver_planningprofile.filter((x) => x.planner).map((it) => it.drivers_id)
    setassigncheckeddriver(allcheckedarr)
    setIsLoading(false);
  }

  useEffect( () => {
    fetchData();
  }, [])

  const updateUserData = async () => {
    try {
      let requestBody = {
        "planning_profile_id": profileById.id,
        "terminal_id": profileById.terminal_id,

        "drivers": assigncheckeddriver
      }
      const UserDriverResponse = await updateAssigneddriversforplanningprofile(requestBody)
      const assigndriver_planningprofile = await getAssigneddriversbyplanningprofile(
        profileById.id

      );
      setassignedDriversList(assigndriver_planningprofile)

      NotificationManager.success("Assigned planning driver updated", "Success");

    }
    catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Failed to uncheck assign driver', { autoClose: false } );
      }
    }
  };
  const handleIsDefaultPlannerCheckbox = (event, data) => {
    // event.preventDefault()
    // let allcheckedarr= assignedDriversList.filter((x )=> x.planner).map((it)=>it.drivers_id)
    if (event.target.checked) {
      if (!assigncheckeddriver.includes(data)) {
        // allcheckedarr.push(data)
        setassigncheckeddriver([...assigncheckeddriver, data])
        // setdriveridchecked([...assigncheckeddriver,data])

      }
    }
    else {
      let modifiedchecked = assigncheckeddriver.filter((it) => it !== data)
      setassigncheckeddriver(modifiedchecked)
    }

  };
  /// end asssign driverlist

  ///assigntrailer list//
  const [dataState1, setDataState1] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [
        {
          field: "is_active",
          operator: "eq",
          value: true,
        },
      ],
    }
  });


  const [dataResult1, setDataResult1] = useState(process(assignTrailerList, dataState1));

  const dataStateChange1 = (event) => {
    setDataResult1(process(assignTrailerList, event.dataState));
    setDataState1(event.dataState);
  };

  useEffect(() => {
    setDataResult1(process(assignTrailerList, dataState1));
  }, [assignTrailerList]);

  const fetchAllData =async () => {
    setIsLoading(true);
    const allterminaltrailers = await getTrailersByTerminalId(
      profileById.terminal_id

    );

    setassignTrailerList(allterminaltrailers);
    setIsLoading(false);
  }
  useEffect( () => {
    fetchAllData();
  }, []);



  /// end assign trailer list
  return (
    <div className="row special_row_flex">
      <div className="col-xl-50">
        <div className="card card_shadow">
          <div className="card-body special_card_padding">
            <div className="access_header_planning">
              <h2 className="header-title">Assign Planning Drivers</h2>
              {permission.can_edit?(
            <button
            type="button"
            className="btn_blue_sm btn-blue ml_10"
            onClick={(e) => updateUserData()}
          // disabled={accessDisabled ? true : false} 
          // style={{background : accessDisabled ? "#dddddd": ""}}
          >
            SAVE
          </button>
              ):(
                <button
                type="button"
                className="btn_blue_sm btn-blue ml_10"
               disabled
              >
                SAVE
              </button>
              )
            }
             
            </div>
            <div className="table-responsive">
              {assignedDriversList?.length > 0 ? (
                <Grid
                  filter={dataState.filter}
                  sort={dataState.sort}
                  sortable={true}
                  filterable={true}
                  // filterOperators={filterOperators}
                  pageable={{
                    pageSizes: [5, 10, 20, 25, 50, 100],
                    info: true,
                    previousNext: true,
                    buttonCount: 10
                  }}
                  resizable={true}
                  skip={dataState.skip}
                  take={dataState.take}
                  data={dataResult}
                  onDataStateChange={dataStateChange}
                // onRowClick={(e) => props.parentCallBackForTerminal(true, e.dataItem)}
                >


                  <GridColumn
                    field="drivers_id"
                    title="Driver ID"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>

                          {e.dataItem.drivers_id ? e.dataItem.drivers_id : ""}

                        </td>
                      );
                    }}
                  />

                  <GridColumn
                    field="driver_name"
                    title="Driver Name"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>

                          {e.dataItem.driver_name ? e.dataItem.driver_name : ""}

                        </td>
                      );
                    }}
                  />



                  <GridColumn
                    title="Terminal"
                    field="terminal"
                    filterable={true}
                    cell={(e) => {

                      return (
                        <td>
                          {e.dataItem.terminal ? e.dataItem.terminal : ""}
                        </td>


                      );
                    }}
                  />

                  <GridColumn
                    field="planner"
                    title="Planners"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          <div className="square1">
                            <input
                              id={e.dataItem.drivers_id + "checkbox"}

                              type="checkbox"
                              onClick={(event) => handleIsDefaultPlannerCheckbox(event, e.dataItem.drivers_id)}
                              defaultChecked={e.dataItem.planner}
                              disabled={e.dataItem.planner}
                              checked=
                              {assigncheckeddriver.find(driver => driver === e.dataItem.drivers_id)}



                            />
                          </div>
                        </td>
                      );
                    }}
                  />


                </Grid>

              ) : isLoading ? (
                <div>
                  <ProgressBar animated now={100} />
                  <div className="middle loader--text1"> </div>
                </div>
              ) : (
                <div className="text-center">No data found</div>

              )}
            </div>
            <NotificationContainer />
          </div>

        </div>
      </div>
      <div className="col-xl-50">
        <div className="card card_shadow">
          <div className="card-body special_card_padding">
            <div className="access_header_planning">
              <h2 className="header-title">Assign Planning Trailers</h2>
              {/* <button type="button" className="btn_blue_sm btn-blue ml_10">SAVE</button> */}
            </div>
            <div className="table-responsive">
              {assignTrailerList?.length > 0 ? (
                <Grid
                  filter={dataState1.filter}
                  sort={dataState1.sort}
                  sortable={true}
                  filterable={true}
                  //filterOperators={filterOperators}
                  pageable={{
                    pageSizes: [5, 10, 20, 25, 50, 100],
                    info: true,
                    previousNext: true,
                    buttonCount: 10
                  }}
                  resizable={true}
                  skip={dataState1.skip}
                  take={dataState1.take}
                  data={dataResult1}
                  onDataStateChange={dataStateChange1}
                //onRowClick={(e) => props.parentcallback(true, e.dataItem)}
                >


                  <GridColumn
                    field="trailer_id"
                    title="Trailer Id"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return <td>{e.dataItem.trailer_id}</td>;
                    }}
                  />

                  <GridColumn
                    field="commoditygroup_full_name"
                    title="Commoditygroup"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.commoditygroup_full_name
                            ? e.dataItem.commoditygroup_full_name
                            : ""}
                        </td>
                      );
                    }}
                  />



                  <GridColumn
                    field="terminal_full_name"
                    title="Terminal"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.terminal_full_name
                            ? e.dataItem.terminal_full_name
                            : ""}
                        </td>
                      );
                    }}
                  />




                  <GridColumn
                    field="is_active"
                    sortable={true}
                    cell={(e) => {
                      return (
                        <td
                          className={classes.statuscolor}
                          style={{
                            color: e.dataItem.is_active ? "#259125" : "#FF0000",
                          }}
                        >
                          {e.dataItem.is_active ? "True" : "False"}
                        </td>
                      );
                    }}
                    title="Is Active"
                    width="150px"
                    filterable={true}
                    filter={"boolean"}
                  />
                </Grid>
              ) : isLoading ? (
                <div>
                  <ProgressBar animated now={100} />
                  <div className="middle loader--text1"> </div>
                </div>
              ) : (
                <div className="text-center">No data found</div>

              )}
            </div>
          </div>
        </div>
      </div>


    </div>
  );
};

export default Planningtab;
