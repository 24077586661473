import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Typeahead } from "react-bootstrap-typeahead";
import {getAllCommodityGroups} from "../../services/commoditygroupService";
import {getShipperPoolsById, getShipperPoolByLocationID, createShipperPool, 
  saveShipperPool, deleteShipperPool} from "../../services/locationService";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";

import { ContextData } from "../../components/appsession";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import ProgressBar from "react-bootstrap/ProgressBar";
import { DateTime } from "luxon";
import { Autocomplete } from "@mui/lab";
import { TextField } from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import errorMessageDisplay from "../../utils/errorMessageDisplay";

const LocationShipperPoolBody = (props) => {
  const [userData, setuserData] = useContext(ContextData);

  // const planners = userData.roles?.map((e) => e.permissionAccess);
  // const commodity = planners[0].filter(
  //   (element) => element.permission === "Locations"
  // );

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });

  const [inViewModaldataState, setinViewModaldataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [ ],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });
  const { locationById, accessDisabled } = props;
  const [searchtext, setSearchText] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [allCGs, setallCG] = useState([]);
  const [seletedCG, setseletedCG] = useState({});

  const [allShipperPoolTrailers, setallShipperPoolTrailers] = useState([]);
  const [allShipperPool, setallShipperPool] = useState([]);
  const [showEditModal, setshowEditModal] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [showViewModal, setshowViewModal] = useState(false);
  const [editDisable, setEditDisable] = useState(false);
  const [loading, setLoading] = useState(false);

  const [shipperPoolDetails, setshipperPoolDetails] = useState({});
  const [shipperForEdit, setshipperForEdit] = useState({});

  const [EffectiveDate, setEffectiveDate] = useState(null);
  const [ExpiryDate, setExpiryDate] = useState(null);

  const [dataResult, setDataResult] = useState(
    process(allShipperPool, dataState)
  );
  const [
    dataResultallShipperPoolTrailers,
    setdataResultallShipperPoolTrailers,
  ] = useState(false);

  const currentDate = new Date();

  const currentDayOfMonth = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const dateString =
    currentYear +
    "-" +
    ("0" + (currentMonth + 1)).slice(-2) +
    "-" +
    ("0" + currentDayOfMonth).slice(-2);

  const dataStateChange = (event) => {
    setDataResult(process(allShipperPool, event.dataState));
    setDataState(event.dataState);
  };

  const inviewModaldataStateChange = (event) => {
    setdataResultallShipperPoolTrailers(
      process(allShipperPoolTrailers, event.dataState)
    );
    setinViewModaldataState(event.dataState);
  };

  const showTrailersInPool = async (e) => {
    //API Call For Shipper Pool
    let allShipperPoolTrailers = await getShipperPoolsById(
      e.id
    );
    if (allShipperPoolTrailers.length > 0) {
      setallShipperPoolTrailers(allShipperPoolTrailers);
      setdataResultallShipperPoolTrailers(
        process(allShipperPoolTrailers, dataState)
      );
      setshowViewModal(true);
    }
  };

  useEffect(() => {
    setDataResult(process(allShipperPool, dataState));
  }, [allShipperPool]);


  const fetchData =async () => {
    try {
      const allShipperPools = await getShipperPoolByLocationID(
        locationById.id.toString(),
        searchtext
      );
      if (allShipperPools.length > 0) {
        let newDataValue = [];
        for (let index = 0; index < allShipperPools.length; index++) {
          const temp = allShipperPools[index];
          temp.effective_date_utc = DateTime.fromMillis(
            parseInt(temp.effective_date_utc * 1000)
          )
            .toFormat("MM-dd-yyyy")
            .toString();
          if (temp.expiration_date_utc === null) {
             temp.expiration_date_utc = "";
          } else {
            temp.expiration_date_utc = DateTime.fromMillis(
              parseInt(temp.expiration_date_utc * 1000)
            )
              .toFormat("MM-dd-yyyy")
              .toString();
          }
          newDataValue.push(temp);
        }
        setallShipperPool(newDataValue);
        props.parentCallBackFromShipperPoll(newDataValue);
      }
    } catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Failed to fetch shipper pool by Id', { autoClose: false } );
      }
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  const getAllCGs = async () => {
    const allCgs = await getAllCommodityGroups();
    setallCG(allCgs);
  };

  useEffect(() => {
    getAllCGs();
  }, []);

  const commodityGroupIdHandler = (e, value) => {
    if (value) {
      setseletedCG(value);
      setshipperPoolDetails((item) => ({
        ...item,
        commodityid: value.code,
        locationid: locationById.id.toString(),
      }));
    }
  };

  const captureTargetCount = function (value) {
    setshipperPoolDetails((item) => ({
      ...item,
      targetcount: value,
    }));
  };

  const captureEffectiveDate = function (value) {
    setshipperPoolDetails((item) => ({
      ...item,
      effectivedate: value,
    }));
  };

  const captureExpiryDate = function (value) {
    setshipperPoolDetails((item) => ({
      ...item,
      expirationdate: value,
    }));
  };
  const dateFormator = (date) => {
    return (
      date.getDate() +
      "-" +
      date.toLocaleString("default", { month: "short" }) +
      "-" +
      date.getFullYear()
    );
  };
  const editShipperPool = (shipper) => {
    const effective_date_utc = DateTime.fromFormat(shipper.effective_date_utc, 'MM-dd-yyyy');
    const expiration_date_utc=DateTime.fromFormat(shipper.expiration_date_utc, 'MM-dd-yyyy');
    setExpiryDate(expiration_date_utc);
    setEffectiveDate(effective_date_utc)
    setshipperForEdit(shipper);

    setshowEditModal(true);
    setEditDisable(true);
  };

  const captureExpiryDateForEdit = (value) => {
    if (value) {
      setshipperForEdit((item) => ({
        ...item,
        expiration_date: value,
      }));
    }
  };

  const captureEffectiveDateForEdit = (value) => {
    setshipperForEdit((item) => ({
      ...item,
      effectivedate: value,
    }));
  };
  const commodityGroupIdHandlerForEdit = (value) => {
    if (value[0]) {
      setseletedCG(value[0]);
      setshipperForEdit((item) => ({
        ...item,
        commodityid: value[0].code,
        locationid: locationById.id.toString(),
      }));
    }
  };

  const captureTargetCountForEdit = (value) => {
    if (value) {
      setshipperForEdit((item) => ({
        ...item,
        targetcount: value,
      }));
    }
  };

  const createShipperPoolFunction = async () => {
    try {
      const effectivedate = DateTime.fromISO(EffectiveDate).toMillis();
      const expirationdate = DateTime.fromISO(ExpiryDate).toMillis()
      

      let payload = {
        commodityid: shipperPoolDetails.commodityid,
        effectivedate: effectivedate,
        expirationdate: expirationdate,
        locationid: shipperPoolDetails.locationid,
        targetcount: shipperPoolDetails.targetcount,
      };
      
      if(effectivedate > expirationdate){
        return toast.error("Expiration Date can't be before Effective Date", "Error");
      }
      if (
        !payload.commodityid ||
        !payload.effectivedate ||
        !payload.locationid ||
        !payload.targetcount
      ) {
        return toast.error("Please fill all required fields", "Error");
      }

      const shipperPools = await createShipperPool(payload);
      if (shipperPools.length > 0) {
        let newDataValue = [];
        for (let index = 0; index < shipperPools.length; index++) {
          const temp = shipperPools[index];
          temp.effective_date_utc = DateTime.fromMillis(
            parseInt(temp.effective_date_utc * 1000)
          )
            .toFormat("MM-dd-yyyy")
            .toString();
          if (temp.expiration_date_utc === null) {
            temp.expiration_date_utc = "";
          } else {
            temp.expiration_date_utc = DateTime.fromMillis(
              parseInt(temp.expiration_date_utc * 1000)
            )
              .toFormat("MM-dd-yyyy")
              .toString();
          }
          newDataValue.push(temp);
        }
        setallShipperPool(newDataValue);
        props.parentCallBackFromShipperPoll(newDataValue);
        setModalShow(false);
        setEffectiveDate(null);
        setExpiryDate(null);
      }
      return toast.success("Shipper Pool Added successfully", "Success");
    } catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Shipper Pool  not Added successfully, Please Refresh Your Screen', { autoClose: false } );
      }
    }
  };

  const saveShipperPoolFunction = async () => {
    try {
      const effectivedate = DateTime.fromISO(EffectiveDate).toMillis();
      const expirationdate = DateTime.fromISO(ExpiryDate).toMillis()
      if(effectivedate > expirationdate){
        return toast.error("Expiration Date can't be before Effective Date", "Error");
      }
      let payLoad = {
        id: shipperForEdit.id,
        locationid: shipperForEdit.location_id,
        commodityid: shipperForEdit.commoditygroup_id,

        effectivedate: effectivedate,
        expirationdate: expirationdate,
        targetcount: shipperForEdit.targetcount,
      };

      const shipperPools = await saveShipperPool(payLoad);

      if (shipperPools.length > 0) {
        let newDataValue = [];
        for (let index = 0; index < shipperPools.length; index++) {
          const temp = shipperPools[index];
          temp.effective_date_utc = DateTime.fromMillis(
            parseInt(temp.effective_date_utc * 1000)
          )
            .toFormat("MM-dd-yyyy")
            .toString();
          if (temp.expiration_date_utc === null) {
            temp.expiration_date_utc = "";
          }else{
            temp.expiration_date_utc = DateTime.fromMillis(
              parseInt(temp.expiration_date_utc * 1000)
            )
              .toFormat("MM-dd-yyyy")
              .toString();
          }
          newDataValue.push(temp);
        }
        setallShipperPool(newDataValue);
        props.parentCallBackFromShipperPoll(newDataValue);
        setshowEditModal(false);
      }

      return toast.success("Shipper Pool Updated successfully", "Success");
    } catch(err){
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Shipper Pool  not updated successfully, Please Refresh Your Screen', { autoClose: false } );
      }
    }
  };

  const openDeleteShipperPoolModal = (shipper) => {
    setshipperForEdit(shipper);
    setshowDeleteModal(true);
  };

  const deleteShipperPoolFunction = async () => {
    try {

      let payload = {
        id: shipperForEdit.id,
      };

      const shipperPoolsDeleteResponse =
        await deleteShipperPool(payload);

      if (shipperPoolsDeleteResponse) {
        const allShipperPools =
          await getShipperPoolByLocationID(
            locationById.id.toString(),
            searchtext
          );
        let newDataValue = [];
        for (let index = 0; index < allShipperPools.length; index++) {
          const temp = allShipperPools[index];
          temp.effective_date_utc = DateTime.fromMillis(
            parseInt(temp.effective_date_utc * 1000)
          )
            .toFormat("MM-dd-yyyy")
            .toString();
          if (temp.expiration_date_utc === null) {
            temp.expiration_date_utc = "";
          }else{
            temp.expiration_date_utc = DateTime.fromMillis(
              parseInt(temp.expiration_date_utc * 1000)
            )
              .toFormat("MM-dd-yyyy")
              .toString();
          }  
          newDataValue.push(temp);
        }
        setallShipperPool(newDataValue);
        props.parentCallBackFromShipperPoll(newDataValue);
        setshowDeleteModal(false);
      }
      return toast.success("Shipper Pool Deleted successfully", "Success");
    } catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Shipper Pool Not Deleted', { autoClose: false } );
      }
    }
  };

  const Editdeleteshipperpool = (props) => {
    return (
      <td className="adjustbutton">
        <button
          type="button"
          className="btn_blue_smadjust btn-blue ml_10"
          onClick={() => editShipperPool(props.dataItem)}
          disabled={accessDisabled ? true : false}
        >
          <i className="fa fa-pencil mr_5 del_icon" aria-hidden="true"></i>
          EDIT
        </button>
        <button
          type="button"
          className="btn_blue_smadjust btn-blue ml_10"
          onClick={() => openDeleteShipperPoolModal(props.dataItem)}
          disabled={accessDisabled ? true : false}
        >
          <i className="fa fa fa-trash mr_5 del_icon" aria-hidden="true"></i>
          DELETE
        </button>
      </td>
    );
  };
  const EditDeleteShipperPool = (props) => (
    <Editdeleteshipperpool
      {...props}
      deleteShipperPool={deleteShipperPoolFunction}
      editShipperPool={editShipperPool}
    />
  );

  const handleDateChangeEffectiveDate = (date) => {

    setEffectiveDate(date);
  };

  const handleDateChangeExpiryDate = (date) => {
   
    setExpiryDate(date);
  };

  function handleDateChangeEffectiveDateForEditChange(value) {
    setEffectiveDate(value)
  }

  function handleDateChangeExpiryDateForEditChange(value) {
    setExpiryDate(value)
  }

  function handleCloseCreate() {
    setModalShow(false);
    setEffectiveDate(null);
    setExpiryDate(null);
  }

  return (
    <>
      <ToastContainer position="top-right" theme="colored" />
      <div className="row mt_30">
        <div className="col-xl-12">
          <div className="card card_shadow">
            <div className="card-body">
              <div className="table_header_section">
                <div>
                  <h2 className="header-title1">
                    Shipper Pool: {allShipperPool?.length}
                  </h2>
                </div>
                <div className="df">
                  <button
                    type="button"
                    className="btn_blue btn-blue"
                    onClick={() => setModalShow(true)}
                    disabled={accessDisabled ? true : false}
                    // style={{background: accessDisabled ? disabled : ""}}
                  >
                    ADD
                  </button>
                </div>
              </div>
              {allShipperPool?.length > 0 ? (
                <Grid
                filter={dataState.filter}
                    filterable={true}
                    sort={dataState.sort}
                    sortable={true}
                    pageable={{
                      pageSizes: [5, 10, 20, 25, 50, 100],
                      info: true,
                      previousNext: true,
                      buttonCount: 10,
                    }}
                    resizable={true}
                    skip={dataState.skip}
                    take={dataState.take}
                    data={dataResult}
                    onDataStateChange={dataStateChange}
                  onRowClick={(e) => showTrailersInPool(e.dataItem)}
                >
                  <GridColumn title="Action" cell={EditDeleteShipperPool} />
                  <GridColumn
                    field="commoditygroup_id"
                    title="CommodityGroup"
                  />
                  <GridColumn field="target_count" title="Target Count" />
                  <GridColumn
                    field="effective_date_utc"
                    title="Effective Date"
                  />
                  <GridColumn
                    field="expiration_date_utc"
                    title="Expiration Date"
                  />
                </Grid>
              ) : loading ? (
                <div>
                  <ProgressBar animated now={100} />
                  <div className="middle loader--text1"> </div>
                </div>
              ) : (
                <div>No data found</div>
              )}
            </div>
          </div>
        </div>
        {/* Modal For Add */}
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              ADD SHIPPER POOL
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="form-group">
              <label for="typeHeadCommodityGroup">Commodity Group *</label>
              <div className="meterial_autocomplete">
                <Autocomplete
                  id="combo-box-demo"
                  options={allCGs}
                  getOptionLabel={(option) =>
                    `${option.code} : ${option.description}`
                  }
                  onChange={commodityGroupIdHandler}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose a Commodity Group..."
                      variant="outlined"
                    />
                  )}
                />
              </div>
              <label for="txtTargetCount">Target Count *</label>
              <input
                name="count"
                type="number"
                className="form-control label_padding"
                id="txtTargetCount"
                onInput={(event) => captureTargetCount(event.target.value)}
                placeholder="e.g. 10"
              />
              <label for="txtEffectiveDate">Effective Date *</label>
              <div className="meterial_autocomplete">
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    disableToolbar
                    format="MM-dd-yyyy"
                    id="date-picker-inline"
                    fullWidth="true"
                    // minDate={dateString}
                    disablePast
                    error={false}
                    helperText={null}
                    inputVariant="outlined"
                    value={EffectiveDate}
                    placeholder="MM-dd-yyyy"
                    onChange={handleDateChangeEffectiveDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </LocalizationProvider>
              </div>
              <label for="txtExpiryDate">Expiration Date</label>
              <div className="meterial_autocomplete">
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    disableToolbar
                    format="MM-dd-yyyy"
                    id="date-picker-inline"
                    fullWidth="true"
                    inputVariant="outlined"
                    // value={dateString}
                    dateString
                    minDate={EffectiveDate}
                    error={false}
                    helperText={null}
                    placeholder="MM-dd-yyyy"
                    onChange={handleDateChangeExpiryDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleCloseCreate}>Close</Button>
            <Button type="submit" onClick={(e) => createShipperPoolFunction(e)}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        {/* modal for edit */}
        <Modal
          show={showEditModal}
          onHide={() => setshowEditModal(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit SHIPPER POOL
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label for="typeHeadCommodityGroup">Commodity Group *</label>
              <div className="meterial_autocomplete">
                <Typeahead
                  id="typeHeadCommodityGroup"
                  //labelKey="id"
                  onChange={commodityGroupIdHandlerForEdit}
                  disabled={editDisable}
                  options={allCGs}
                  labelKey={(option) => `${option.code}`}
                  defaultSelected={
                    shipperForEdit.commoditygroup_id
                      ? [{ code: shipperForEdit.commoditygroup_id }]
                      : []
                  }
                  // defaultSelected={
                  //   shipperForEdit.commoditygroup_id
                  //     ? [{ code: shipperForEdit.commoditygroup_id }]
                  //     : []
                  // }

                  placeholder="Choose a Commodity..."
                  clearButton
                />
              </div>

              <label for="txtTargetCount">Target Count *</label>
              <div className="meterial_autocomplete">
                <input
                  type="number"
                  className="form-control"
                  id="txtTargetCount"
                  onInput={(event) =>
                    captureTargetCountForEdit(event.target.value)
                  }
                  placeholder="e.g. 10"
                  defaultValue={shipperForEdit.target_count}
                  disabled={editDisable}
                />
              </div>

              <label for="txtEffectiveDate">Effective Date *</label>
              <div className="meterial_autocomplete">
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    disableToolbar
                    format="MM-dd-yyyy"
                    id="date-picker-inline"
                    fullWidth="true"
                    // minDate={dateString}
                    inputVariant="outlined"
                    variant="outlined"
                    disabled={true}
                    value={EffectiveDate}
                    error={false}
                    helperText={null}
                    placeholder="MM-dd-yyyy"
                    onChange={handleDateChangeEffectiveDateForEditChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </LocalizationProvider>
              </div>

              <label for="txtExpiryDate">Expiration Date</label>
              <div className="meterial_autocomplete">
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    disableToolbar
                    format="MM-dd-yyyy"
                    id="date-picker-inline"
                    fullWidth="true"
                    variant="outlined"
                    inputVariant="outlined"
                    value={ExpiryDate}
                    error={false}
                    helperText={null}
                    // minDate={dateString}
                    placeholder="MM-dd-yyyy"
                    onChange={handleDateChangeExpiryDateForEditChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={(e) => setshowEditModal(false)}>Close</Button>
            <Button onClick={(e) => saveShipperPoolFunction(e)}>Save</Button>
          </Modal.Footer>
        </Modal>

        {/* modal for Delete */}
        <Modal
          show={showDeleteModal}
          onHide={() => setshowDeleteModal(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete Shipper Pool
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <p>
                Are you sure you want to Delete target count{" "}
                {shipperForEdit.target_count} ?
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={(e) => setshowDeleteModal(false)}>Close</Button>
            <Button onClick={(e) => deleteShipperPoolFunction(e)}>Delete</Button>
          </Modal.Footer>
        </Modal>

        {/* modal for View */}
        <Modal
          show={showViewModal}
          size="lg"
          onHide={() => setshowViewModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Trailers In the Pool
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {allShipperPoolTrailers?.length > 0 ? (
              <Grid
                filter={inViewModaldataState.filter}
                filterable={true}
                sort={inViewModaldataState.sort}
                sortable={true}
                pageable={{
                  buttonCount: 10,
                  info: true,
                  previousNext: true,
                  pageSizes: true,
                }}
                resizable={true}
                skip={inViewModaldataState.skip}
                take={inViewModaldataState.take}
                data={dataResultallShipperPoolTrailers}
                onDataStateChange={inviewModaldataStateChange}
              >
                <GridColumn field="trailer_id" title="Trailer ID" />
                <GridColumn field="type" title="Type" />
                <GridColumn field="last_move_order" title="Last Move / Order" />
                <GridColumn field="current_status" title="Current Status" />
                <GridColumn field="current_location" title="Current Location" />
              </Grid>
            ) : loading ? (
              <div>
                <ProgressBar animated now={100} />
                <div className="middle loader--text1"> </div>
              </div>
            ) : (
              <div>No data found</div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={(e) => setshowViewModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default LocationShipperPoolBody;
