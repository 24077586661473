import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import errorMessageDisplay from "../src/utils/errorMessageDisplay"

const appConfig = require("./Config.json");
// Initialize Firebase App
initializeApp(appConfig.firebaseConfig);

const messaging = getMessaging();

export const requestForToken = async () => {
  try {
    const firebaseClientToken = await getToken(messaging, { vapidKey: appConfig.fbVapidKey});
    return firebaseClientToken;
  }catch(err){
    if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
      errorMessageDisplay(err?.response?.data?.errors);
    }
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("🚀 ~ onMessage ~ payload:", payload)
      resolve(payload);
    });
  });