import { BaseConfig } from "../baseConfig";
import axios from 'axios';

export const getWashDuration = async (wash_id) => {
  try {
    const response = await axios.get(
      `${BaseConfig.ApiEndPoint}/getwashcodeduration/${wash_id}`
    );
    return Promise.resolve(response.data.data);
  } catch {
    return Promise.reject("Unable to retrieve wash duration");
  }
};

export const getTrailerWashWorkOrders = async (filterData) => {
  let tankwashObject = [];

  try {
    const requestData = {
      // needed_date_utc: filterData.needed_date_utc,
      wash_location_id: filterData.wash_location_id,
    };

    const url = BaseConfig.ApiEndPoint + "/getalltrailerwashworkorders";
    const response = await axios.post(url, requestData);

    tankwashObject = response.data.data;

  } catch (error) {
    return Promise.reject(
      "There is a problem on retrieving Trailer Wash Work Order data. Please try again!"
    );
  }

  return Promise.resolve(tankwashObject);
};

export const getAllDataByGenericAPI = async (tablename, payload) => {
  try {
      const url = `${BaseConfig.ApiEndPoint}/grcforgetall/${tablename}`;
        const response = await axios.post(url, payload);
        return response.data.data;
    }
    catch (err) {
        throw err
    }
}

export const getTrailerWashWorkOrdersforplanningboard = async (payload) => {
  try {
    const url = BaseConfig.ApiEndPoint + "/gettrailerwashorders";
    const response = await axios.post(url, payload);
     return Promise.resolve(response.data.data);
  } catch (error) {
    return Promise.reject(
      "There is a problem on retrieving Trailer Wash Work Order data. Please try again!"
    );
  }
};

export const updateTrailerWashWorkOrders = async (filterData) => {
  let tankwashObject = [];

  try {
    const requestData = {
      start_time_utc: filterData.start_time_utc,
      end_time_utc: filterData.end_time_utc,
      bay: filterData.bay,
      is_scheduled: filterData.is_scheduled,
    };

    const url = BaseConfig.ApiEndPoint + "/updatetrailerwashworkorders/" + filterData.id;
    const response = await axios.put(url, requestData);
    tankwashObject = response;
  } catch (error) {
    return Promise.reject(
      "There is a problem on updating Trailer Wash Work Order data. Please try again!"
    );
  }

  return Promise.resolve(tankwashObject);
};


export const updateGeneric = async (tabname, payload, id) => {
  try {
      const url = `${BaseConfig.ApiEndPoint}/grcforupdate/${tabname}/${id}`;
      const response = await axios.put(url, payload);
      return response
  }
  catch (err) {
      throw err
  }
}

export const Removetankwashorder = async (filterData) => {
  let tankwashObject = [];

  try {
    const requestData = {
      is_scheduled: filterData.is_scheduled,
      start_time_utc: filterData.start_time_utc,
      end_time_utc: filterData.end_time_utc,
      bay: filterData.bay
    };

    const url =
      BaseConfig.ApiEndPoint + "/updatetrailerwashworkorders/" + filterData.id;

    const response = await axios.put(url, requestData);

    tankwashObject = response.data.data;
  } catch (error) {
    return Promise.reject(
      "There is a problem on updating Trailer Wash Work Order data. Please try again!"
    );
  }

  return Promise.resolve(tankwashObject);
};

export const getAllWashcodes = async () => {
  let tankwashObject = [];

  try {
    const url = BaseConfig.ApiEndPoint + "/getAllWashcodes";
    const tankwashApiData = await axios.get(url);
    tankwashObject = tankwashApiData.data.data;
  } catch (err) {
    return Promise.reject(
      "There is a problem on retrieving role data. Please try again!"
    );
  }

  return Promise.resolve(tankwashObject);
};

export const getAlltankwashtypeprocedure = async () => {
  let tankwashObject = [];

  try {
    const url = BaseConfig.ApiEndPoint + "/getalltankwashtypeprocedure";
    const tankwashApiData = await axios.get(url);
    tankwashObject = tankwashApiData.data.data;
  } catch (err) {
    return Promise.reject(
      "There is a problem on retrieving role data. Please try again!"
    );
  }

  return Promise.resolve(tankwashObject);
};

export const getAllWashprocedures = async () => {
  let tankwashObject = [];

  try {
    const url = BaseConfig.ApiEndPoint + "/getallwashprocedures";
    const tankwashApiData = await axios.get(url);
    tankwashObject = tankwashApiData.data.data;
  } catch (err) {
    return Promise.reject(
      "There is a problem on retrieving role data. Please try again!"
    );
  }

  return Promise.resolve(tankwashObject);
};

export const createtankwashprocedure = async (data) => {
  let tankwashObject = [];
  try {
    const url = BaseConfig.ApiEndPoint + "/createwashodetype";
    const tankwashApiData = await axios.post(url, data);
    tankwashObject = tankwashApiData.data.data;
  } catch (error) {
    return Promise.reject("Error: Unable to add the Terminal Contact.");
  }
  return Promise.resolve(tankwashObject);
};

export const createwashprocedure = async (data) => {
  let tankwashObject = [];
  try {
    const url = BaseConfig.ApiEndPoint + "/createwashprocedures";
    const tankwashApiData = await axios.post(url, data);
    tankwashObject = tankwashApiData.data.data;
  } catch (error) {
    return Promise.reject("Error: Unable to add the Terminal Contact.");
  }
  return Promise.resolve(tankwashObject);
};

export const updateTankWash = async (data) => {
  let tankwashObject = [];
  let payload = {
    active: data?.active,
    category: data?.category,
    description: data?.description,
    optional: data?.optional,
  };
  try {
    const url = BaseConfig.ApiEndPoint + "/updatewashprocedures/" + data.id;
    const tankwashApiData = await axios.put(url, payload);
    tankwashObject = tankwashApiData.data.data;
  } catch (error) {
    return Promise.reject("Error: Unable to add the Terminal Contact.");
  }
  return Promise.resolve(tankwashObject);
};
export const getTankwashTypeById = async(id) => {
  try {
    const url = BaseConfig.ApiEndPoint + "/getwashcodetype/" + id;
    const getTankWashData=await axios.get(url);
    return Promise.resolve(getTankWashData);
  } catch (error) {
    return Promise.reject("Error: Unable get tank Wash type");
  }

}
export const updateTankWashCode = async (data) => {
  let tankwash = [];
  let payload = {
    "wash_code": data.wash_code,
    "description": data.description,
    "is_active" : data.is_active,
    "type": data.type,
    "minutes": data.minutes,
    "amount": data.amount,
    "wash_type_procedure": data.wash_type_procedure
  };
  try {
    const url = BaseConfig.ApiEndPoint + "/updatewashcodetype/" + data.id;
    const tankwashApiData = await axios.put(url, payload);
    tankwash = tankwashApiData.data.data;
  } catch (error) {
    return Promise.reject("Error: Unable to add the Terminal Contact.");
  }
  return Promise.resolve(tankwash);
};
export const getAlllocationsbyterminaliswash = async () => {
  let tankwashlocationObject = [];

  try {
    const url = BaseConfig.ApiEndPoint + "/locationsbyterminaliswash";
    const tankwashApiData = await axios.get(url);
    tankwashlocationObject = tankwashApiData.data;
  } catch (err) {
    return Promise.reject(
      "There is a problem on retrieving role data. Please try again!"
    );
  }

  return Promise.resolve(tankwashlocationObject);
};

export const getAllActiveTrailer = async () => {
  let allActiveTrailerObj;
  try {
      const url = BaseConfig.ApiEndPoint + "/getallactivetrailer";
      const allActiveTrailerData = await axios.get(url);
      allActiveTrailerObj = allActiveTrailerData.data.data;
  } catch (err) {
      return Promise.resolve("There is a problem on retrieving all active trailers. Please try again!");
  }
  return Promise.resolve(allActiveTrailerObj);
}

export const getAllActiveTractor = async () => {  
  let allActiveTractorObj;

  try {
      const url = BaseConfig.ApiEndPoint + "/getallactivetractor";
      const allActiveTrailerData = await axios.get(url);
      allActiveTractorObj = allActiveTrailerData.data.data;
  } catch (err) {
      return Promise.resolve("There is a problem on retrieving all active tractors. Please try again!");
  }
  return Promise.resolve(allActiveTractorObj);
}

export const getWashCode = async () => {
 let allWashCode;
  try {
      const url = BaseConfig.ApiEndPoint + "/getallwashcodes";
      const allWashCodeData = await axios.get(url);      
      allWashCode = allWashCodeData.data.data;
  } catch (err) {
      return Promise.resolve("There is a problem on retrieving all wash codes. Please try again!");
  }
  return Promise.resolve(allWashCode);
}

export const getWashLocationForVendorPayable = async () => {
  let allWashLoaction;
   try {
       const url = BaseConfig.ApiEndPoint + "/getwashlocation";
       const allWashCodeData = await axios.get(url);      
       allWashLoaction = allWashCodeData.data.data;
   } catch (err) {
       return Promise.resolve("There is a problem on retrieving all wash codes. Please try again!");
   }
   return Promise.resolve(allWashLoaction);
 }

export const createTrailerWash = async (payload) => {
  try {
    const url = BaseConfig.ApiEndPoint + "/createtrailerwash";
    const newWashData = await axios.post(url, payload);
    return newWashData;
  } catch (err) {
    throw err.response;
  }
}


export const getTankWashCode = async (id) => {
  let washbayObject = [];
  try {
      const url = BaseConfig.ApiEndPoint + "/gettrailerwashbyid/" + id;
      const washbayApiData = await axios.get(url);
      washbayObject = washbayApiData.data.data
  } catch (err) {
      return Promise.resolve("There is a problem on retrieving driver schedule data. Please try again!");
  }
  return Promise.resolve(washbayObject);
}
export const updateTrailerWash = async (payload,id) => {
  let newWashDataObj;
   try {
       const url = BaseConfig.ApiEndPoint + "/updatetrailerwash/" + id;
       const newWashCode = await axios.put(url, payload);
       newWashDataObj = newWashCode;
   } catch (err) {
       return err.response;
   }
   return Promise.resolve(newWashDataObj);
}
