import React, { useEffect, useState } from "react";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import { Calendar } from "@progress/kendo-react-dateinputs";
import { Autocomplete } from "@mui/lab";
import { TextField } from "@mui/material";
import { Typeahead } from "react-bootstrap-typeahead";

const TankwashlocationHeader = (props) => {
  
  const {alllocationnamelist,tankwashbayhandeler,selectedLocation, calenderDropdownSelectHandler, selectedDate, setcalenderDrpdownClicked, calenderDrpdownClicked,washLocation } = props
  const [options,setOptions]=useState([])
  useEffect(()=>{
    (() => {
      const options = alllocationnamelist.map((option) => {
        const bulkmatic_locations = option.is_terminal && option.is_wash;
        return {
          locations: bulkmatic_locations ? "Bulkmatic Locations" : "Other Locations",
          ...option
        }
      })
      setOptions(options)
    }
    )();
    },[alllocationnamelist])
 
 
  // const options = alllocationnamelist.map((option) => {
  //   const bulkmatic_locations = option.is_terminal&& option.is_wash;
    
  //   return{
  //     locations:bulkmatic_locations? "Bulkmatic Locations":"Other Locations",
  //     ...option
     
  //   }

  // });
  
 
  return (
    <div className='row df mt_30'>
      <div className='col-xl-12'>
        <div className='card special_bg card_shadow'>
          <div className='card-body'>
            <div className='row top_adjust'>
              <div className='col-md-12'>
                <h2 className='text-light'>Tank Wash</h2>
              </div>
            </div>
            <div className='row df top_adjust'>
              <div className='tankwash_top_header_wrapper'>
                <div className='w-40'>
                  <div className='meterial_autocomplete_tankwash'>
                    <Autocomplete                      
                      id='combo-box-demo'
                      options={options.sort(
                        (a, b) => -b.locations.localeCompare(a.locations)
                      )}
                      // value={selectedLocation}
                      getOptionLabel={(option) =>
                        `${option.code} - ${option.location_name} - ${option.state} - ${option.city}`
                      }
                    
                      onChange={tankwashbayhandeler}
                      groupBy={(option) => option.locations}
                      renderInput={(params,index) => (
                        <TextField
                          {...params}
                          key={index}
                          placeholder='Choose a Location Name...'
                          variant='outlined'
                        />
                      )}
                    />
                  </div>
                </div>
                <div className='multiselect ml_30 pr w-8 terminal_drop_fixed'>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TankwashlocationHeader;