import React, { useState, useEffect } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import axios from "axios";
// import BlukmatikLogo from "../../bulkmatic-planning-frontend/src/assets/images/Bulkmatic Solutions Logo.png";
import BlukmatikLogo from "../src/assets/images/Bulkmatic Solutions Logo.png";

import "react-notifications/lib/notifications.css";
import "./styles/App.css";
import AppRoute from "./AppRoute.js";
import AppSession from "./components/appsession";
import { BaseConfig } from "./baseConfig";
import { loginRequest } from "./authConfig";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

// let userAccessToken = localStorage.getItem("accesstoken");
// axios.defaults.headers.common["Authorization"] = `Bearer ${userAccessToken}`;
// axios.defaults.headers.common["Authorization"] = `Bearer ${BaseConfig.ApiKey}`;
/**
 * If a user is authenticated the Bulkmatic Main Page is rendered.
 * Otherwise a message indicating it is starting the user session.
 */

export default function App() {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None)
      instance.loginRedirect(loginRequest).catch((e) => {
      });
    return () => {};
  }, [inProgress]);

  return (
    <>
      <AuthenticatedTemplate>
        <AppSession>
          <AppRoute />  
          <ToastContainer position='top-right' autoClose={2000} theme='colored' />
        </AppSession>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <div className='blukmatik_login_section'>
          <div className='blukmatik_login_card'>
            <p>
              <img
                src={BlukmatikLogo}
                alt=''
                className='blukmatik_logo_adjust'
              />
            </p>
            <div className='login_button_section'>
              <button
                className='login_button'
                onClick={() => instance.loginRedirect(loginRequest)}
              >
                Sign In
              </button>
            </div>
          </div>
        </div>
      </UnauthenticatedTemplate>
    </>
  );
}
