import React, { useEffect, useState, useContext } from "react";
import { getAllWashprocedures, updateTankWash,createwashprocedure} from "../../services/tankWashService";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { makeStyles } from "@mui/styles";
import ProgressBar from "react-bootstrap/ProgressBar";
import TextField from "@mui/material/TextField";
import { Tooltip } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Autocomplete } from "@mui/lab";
import { ToastContainer, toast } from "react-toastify";
import { ContextData } from "../../components/appsession";
import errorMessageDisplay from "../../utils/errorMessageDisplay";

const Tankwashproceduretable = (props) => {
  const { accessDisabled } = props;
  const [tankwashcode,settankwashcode]=useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [dataforaddTankprocedure, setdataforaddTankprocedure] = useState({});
  const [showEditModal, setshowEditModal] = useState(false);
  const [editDataforTankprocedure, setEditdataforTankprocedure] = useState({});
  const [userData, setuserData] = useContext(ContextData);

  useEffect(() => {
    accessDisabled ? true : false;
  }, [accessDisabled]);
  
  const [dataForBoolean, setdataForBoolean] = useState([
    {
        active: "Yes",
    },
    {
        active: "No",
    },
  ]);

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });

  const [dataResult, setDataResult] = useState(process(tankwashcode, dataState));

  const dataStateChange = (event) => {
    setDataResult(process(tankwashcode, event.dataState));
    setDataState(event.dataState);
  };

  useEffect(() => {
    setDataResult(process(tankwashcode, dataState));
  }, [tankwashcode]);

  const fetchData =async () => {
    setIsLoading(true);
    const washcodedata = await getAllWashprocedures()
    settankwashcode(washcodedata)
    setIsLoading(false);

  }

  useEffect(()=>{
    fetchData();
  },[])
  const categoryChangeHandler = (e) => {
    setdataforaddTankprocedure((prevState) => ({
      ...prevState,
      category: e.target.value,
    }));
  };
  const descriptionChangeHandler = (e) => {
    setdataforaddTankprocedure((prevState) => ({
      ...prevState,
      description: e.target.value,
    }));
  };
  const activeChangeHandler = (e,value) => {
    if(value != null){
        setdataforaddTankprocedure((prevState) => ({
            ...prevState,
            active: value.active,
          }));
    } else {
      setdataforaddTankprocedure((prevState) => ({
        ...prevState,
        active: null,
      }));
    }
   
  };
  const saveTankprocedureHandler = async()=>{
    let updateArr = [];
    if(
        !dataforaddTankprocedure.description || 
        !dataforaddTankprocedure.category || 
        !dataforaddTankprocedure.active
      ){
      return toast.error(
        `Please fill all the fields`,
        "Error"
      );
    } else{
      try{
        const Addtankprocedure = await createwashprocedure(dataforaddTankprocedure);
        if(Addtankprocedure){
          updateArr.push(Addtankprocedure)
          settankwashcode(updateArr)
          setModalShow(false)
          const allwash = await getAllWashprocedures()
          settankwashcode(allwash)
          setdataforaddTankprocedure({})
          return toast.success(
            `Wash procedure added successfully`,
            "Success"
          );
        }
      } catch(err) {
        console.log(err)
        setModalShow(false)
        setdataforaddTankprocedure({})
          if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
            errorMessageDisplay(err?.response?.data?.errors);
          }else{
          toast.error('Wash procedure not added successfully, Please Refresh Your Screen', { autoClose: false } );
          }
        }
    }
  }
  const activeChangeHandlerEdit = (e, value) => {
    if(value != null){
      setEditdataforTankprocedure((prevState) => ({
        ...prevState,
        active : value.active
      }));
    } 
   
  }
  const categoryChangeHandlerEdit = (e, value) => {
    setEditdataforTankprocedure((prevState) => ({
      ...prevState,
      category: e.target.value
    }));
  }
  const descriptionChangeHandlerEdit = (e, value) => {
    setEditdataforTankprocedure((prevState) => ({
      ...prevState,
      description: e.target.value
    }));
  }
  const updateTankprocedure = async() => {
   // let updateArr = [];
   
    if (
        !editDataforTankprocedure.description ||
        !editDataforTankprocedure.category ||
        !editDataforTankprocedure.active
      ) {
      return toast.error("Please fill all required fields", "Error");
    }
    try{
      let tankWashResponse = await updateTankWash(editDataforTankprocedure);
      //updateArr.push(editDataforTankprocedure)
      if(tankWashResponse.length > 0){
        //settankwashcode(updateArr)
        setIsLoading(false)
        setshowEditModal(false)
      const allwash = await getAllWashprocedures()
      settankwashcode(allwash)
        return toast.success(
          `Wash procedure updated successfully`,
          "Success"
        );
      }
    } catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Wash procedure not updated, Please Refresh Your Screen', { autoClose: false } );
      }
    }
}
  const editTankWashProc = (tankWash) => {
    setEditdataforTankprocedure(tankWash)
    setshowEditModal(true);
  }

  const Edittankwash = (props) => {
    return(
      <td className="adjustbutton">
        <button
          type="button"
          className="btn_blue_sm btn-blue ml_10"
          onClick={() => editTankWashProc(props.dataItem)}
          disabled={accessDisabled}
        >
          <i className="fa fa-pencil mr_5 del_icon" aria-hidden="true"></i>
          Edit
        </button>                    
      </td>
    )
  }
  const EditTankWash = (props) => (
    <Edittankwash 
    {...props} 
    editTankWashProc={editTankWashProc}
    />
  )

  return (
    <>
      <ToastContainer position="top-right" autoClose={2000} theme="colored" />
      <div className="row special_row_flex">
      <div className="col-xl-12">
        <div className="card card_shadow">
          <div className="card-body">
          <div className="row">
                <div className="col-md-5 textadjust_terminal">
                </div>
                <div className="col-md-7 textadjust_terminaladd_button ">
                  <div className="add_icon">
                  
                      <Tooltip title="Add Contact">
                        <button
                          type="button"
                          className="btn_blue btn-blue mr_10 mb-20 "
                          onClick={() => setModalShow(true)}
                          disabled={accessDisabled}
                        >
                          ADD
                        </button>
                      </Tooltip>
                    
                  </div>
                </div>
              </div>

            <div className="table-responsive">
              {tankwashcode?.length > 0 ? (
                <Grid
                  filter={dataState.filter}
                  filterable={true}
                  //filterOperators={filterOperators}
                  sort={dataState.sort}
                  sortable={true}
                  pageable={{
                    pageSizes: [5, 10, 20, 25, 50, 100],
                    info: true,
                    previousNext: true,
                    buttonCount : 10
                  }}
                  resizable={true}
                  skip={dataState.skip}
                  take={dataState.take}
                  data={dataResult}
                  onDataStateChange={dataStateChange}
                  //onRowClick={(e) => props.parentcallback(true, e.dataItem)}
                >
                
                  <GridColumn
                    field="description"
                    title="Description"
                  
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>{e.dataItem.description ? e.dataItem.description : ""}</td>
                      );
                    }}
                  />
                    <GridColumn
                    field="category"
                    title="Category"
                
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>{e.dataItem.category ? e.dataItem.category : ""}</td>
                      );
                    }}
                  />
                  <GridColumn
                    field="optional"
                    title="Is Optional"
                  
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>{e.dataItem.optional ? e.dataItem.optional : ""}</td>
                      );
                    }}
                  />
                  <GridColumn
                    field="active"
                    title="Active"
                  
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>{e.dataItem.active ? e.dataItem.active : ""}</td>
                      );
                    }}
                  />
                  <GridColumn
                    title="Action"
                    cell={EditTankWash}
                  />
                </Grid>
              ): isLoading ? (
                  <div>
                    <ProgressBar animated now={100} />
                    <div className="middle loader--text1"> </div>
                  </div>
                ) : (
                  <div className="text-center">No data found</div>

                )}
            </div>


          </div>
        </div>
          {/* modal for Add */}

          <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
              Add Wash procedure
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="planning_profile_popup_textbox">
            <div className="meterial_autocomplete">
                <label htmlFor="exampleAddFormControlTextarea1">Description</label>
                <TextField
                  variant="outlined"
                  type="text"
                  className="form-control"
                  placeholder="Description...."
                  id="exampleAddFormControlTextarea1"
                  rows="3"
                  onChange={(e) => descriptionChangeHandler(e)}
                />
                </div>
              </div>
              <div className="planning_profile_popup_textbox">
            <div className="meterial_autocomplete">
                <label htmlFor="exampleAddFormControlTextarea1">Category</label>

                <TextField
                  variant="outlined"
                  type="text"
                  className="form-control"
                  placeholder="Category...."
                  id="exampleAddFormControlTextarea1"
                  rows="3"
                  onChange={(e) => categoryChangeHandler(e)}
                />
                </div>
              </div>
              <div className="meterial_autocomplete">
                <label htmlFor="typeHeadAddStatus">Active</label>

                <Autocomplete
                  id="combo-box-demo"
                  options={dataForBoolean}
                  getOptionLabel={(option) => `${option.active}`}
                  onChange={activeChangeHandler}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose Active..."
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={(e) => setModalShow(false)}>Close</Button>
            <Button type="submit" onClick={(e) => saveTankprocedureHandler(e)}>
              Save
            </Button>
            </Modal.Footer>
          </Modal>
          {/* modal for Edit */}

          <Modal
            show={showEditModal}
            onHide={() => setshowEditModal(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
              Edit Wash procedure
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="planning_profile_popup_textbox">
            <div className="meterial_autocomplete">
                <label htmlFor="exampleAddFormControlTextarea1">Description</label>
                <TextField
                  variant="outlined"
                  type="text"
                  className="form-control"
                  placeholder="Description...."
                  id="exampleAddFormControlTextarea1"
                  rows="3"
                  defaultValue={editDataforTankprocedure.description}
                  onChange={descriptionChangeHandlerEdit}
                />
                </div>
              </div>
              
              <div className="planning_profile_popup_textbox">
            <div className="meterial_autocomplete">
                <label htmlFor="exampleAddFormControlTextarea1">Category</label>
                <TextField
                  variant="outlined"
                  type="text"
                  className="form-control"
                  placeholder="Category...."
                  id="exampleAddFormControlTextarea1"
                  rows="3"
                  defaultValue={editDataforTankprocedure.category}
                  onChange={(e) => categoryChangeHandlerEdit(e)}
                />
                </div>
              </div>
              <div className="meterial_autocomplete">
                <label htmlFor="typeHeadAddStatus">Active</label>

                <Autocomplete
                  id="combo-box-demo"
                  options={dataForBoolean}
                  getOptionLabel={(option) => `${option.active}`}
                  defaultValue={{
                    active: editDataforTankprocedure.active ?? ""}}
                  onChange={activeChangeHandlerEdit}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose Active..."
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={(e) => setshowEditModal(false)}>Close</Button>
            <Button type="submit" onClick={updateTankprocedure}>
              Save
            </Button>
            </Modal.Footer>
          </Modal>
      </div>
      </div>
    </>
	);
};

export default Tankwashproceduretable;
