import React, { useState, useEffect } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { makeStyles } from "@mui/styles";
import ProgressBar from "react-bootstrap/ProgressBar";
import QRCode from "react-qr-code";
import QrCodeIcon from '@mui/icons-material/QrCode';
import { Modal, Button } from "react-bootstrap";

const TrailerTable = (props) => {
  const { allTrailers, isLoading, convertDateTime, setallTrailer } = props;
  const useStyles = makeStyles((theme) => ({
    statuscolor: {
      fontWeight: "bold",
      textAlign: "center !important",
      fontSize: 15,
    },
  }));
  const classes = useStyles();

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [
        {
          field: "is_active",
          operator: "eq",
          value: true,
        },
      ],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });

  const [dataResult, setDataResult] = useState(process(allTrailers, dataState));
  const [qrValue,setQRValue] =useState("");
  const [openQR,setOpenQR] = useState(false);
  const[trailerId,setTrailerId]=useState("");

  const dataStateChange = (event) => {
    setDataResult(process(allTrailers, event.dataState));
    setDataState(event.dataState);
  };

  useEffect(() => {
    setDataResult(process(allTrailers, dataState));
  }, [allTrailers]);


  useEffect( () => {

    if (allTrailers?.length > 0 && allTrailers[0]?.terminal?.terminal_id && allTrailers[0]?.terminal?.name) {
      const updatedTrailersData = allTrailers.map(item => {
        item.newTerminal = `${item?.terminal?.terminal_id} - ${item?.terminal?.name}`;
        return item;
      });
      
      setallTrailer(updatedTrailersData);
    }

  }, [allTrailers?.length]);

  const handleOnclickOnQR=(e,newValue,trailerId)=>{
    setQRValue(newValue);
    setOpenQR(true);
    setTrailerId(trailerId);
  }

  return (
  <>
    <div className="row">
      <div className="col-xl-12">
        <div className="card card_shadow">
          <div className="card-body ">
            <div className="table-responsive">
              {isLoading ? 
            (
              <div>
              <ProgressBar animated now={100} />
                    <div className="loader">
                      <div className="loader--text"></div>
              </div>
            </div>
            ) : (
              allTrailers?.length > 0 ? (
                <Grid
                  filter={dataState.filter}
                  sort={dataState.sort}
                  sortable={true}
                  filterable={true}
                  //filterOperators={filterOperators}
                  pageable={{
                    pageSizes: [5, 10, 20, 25, 50, 100],
                    info: true,
                    previousNext: true,
                    buttonCount : 10
                  }}
                  resizable={true}
                  skip={dataState.skip}
                  take={dataState.take}
                  data={dataResult}
                  onDataStateChange={dataStateChange}
                  onRowClick={(e) => props.parentcallback(true, e.dataItem)}
                >
                  <GridColumn
                    field="is_active"
                    sortable={true}
                    cell={(e) => {
                      return (
                        <td
                          className={classes.statuscolor}
                          style={{
                            color: e.dataItem.is_active ? "#259125" : "#FF0000",
                          }}
                        >
                          {e.dataItem.is_active ? "True" : "False"}
                        </td>
                      );
                    }}
                    title="Is Active"
                    width="150px"
                    filterable={true}
                    filter={"boolean"}
                  />
                  <GridColumn
                    field="eqstat"
                    title="Status"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return <td>{e.dataItem.eqstat}</td>;
                    }}
                  />
                  <GridColumn
                    field="trailer_id"
                    title="Trailer Id"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.trailer_id
                            ? e.dataItem.trailer_id
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field="eqmake"
                    title="Make"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.eqmake ? e.dataItem.eqmake : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field="eqmodel"
                    title="Model"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.eqmodel ? e.dataItem.eqmodel : ""}
                        </td>
                      );
                    }}
                  />

                  <GridColumn
                    field="eqlicenseplate"
                    title="License Plate"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.eqlicenseplate
                            ? e.dataItem.eqlicenseplate
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field="driver_side_tag"
                    title="QR code"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.driver_side_tag
                            ?  <div style={{display:"flex",gap:"10px"}}>
                              <div>{e.dataItem.driver_side_tag}</div>
                              <QrCodeIcon sx={{cursor:"pointer"}} onClick={(event)=>handleOnclickOnQR(event,e.dataItem.driver_side_tag,e.dataItem.trailer_id)}/>
                            </div> : ""}
                        </td>
                      );
                    }}
                  />
                  {/* <GridColumn
                    field="eqfleetcode"
                    title="Fleet Code"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.eqfleetcode
                            ? e.dataItem.eqfleetcode
                            : ""}
                        </td>
                      );
                    }}
                  /> */}

                  <GridColumn
                    field="newTerminal"
                    title="Terminal"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.newTerminal
                            ? e.dataItem.newTerminal
                            : ""}
                        </td>
                      );
                    }}
                  />

                  <GridColumn
                    field="region"
                    title="Region"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem?.terminal.region
                            ? e.dataItem?.terminal.region
                            : ""}
                        </td>
                      );
                    }}
                  />

                  <GridColumn
                    field="commoditygroup_full_name"
                    title="Commoditygroup"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.commoditygroup_full_name
                            ? e.dataItem.commoditygroup_full_name
                            : ""}
                        </td>
                      );
                    }}
                  />

                  <GridColumn
                    field="pm_due_date_utc"
                    title="Next PM Date"
                    width="200px"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td
                          style={{
                            padding: e.dataItem.pm_due_date_utc ? "8px 1px" : "8px 10px"
                          }}
                          >
                          {
                            e.dataItem.pm_due_date_utc ? 
                            convertDateTime(e.dataItem.pm_due_date_utc) 
                            : ""
                          }
                        </td>
                      );
                    }}
                  />
                </Grid>
              ) : (
                <div>
                  No data found
                </div>
              )
            )
            }
            </div>
          </div>
        </div>
      </div>
    </div>

      <Modal
        show={openQR}
        onHide={() => { setOpenQR(false), setQRValue("") }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <QRCode value={qrValue} style={{ margin: '30px' }} />
        <p><strong>QR Code value:</strong> {qrValue}</p>
        <p><strong>Trailer Id:</strong> {trailerId}</p>
      </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => { setOpenQR(false), setQRValue("") }}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal> 
  </>
  );
};

export default TrailerTable;
