import axios from "axios";
import { graphConfig } from "./authConfig";
import {getUser, createUser} from "./services/userService";
import { DateTime } from "luxon";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function callMsGraph(accessToken) {

    const options = {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    };
    

    try {
        const graphData = await axios.get(graphConfig.graphMeEndpoint, options);

        if(graphData){


            const id = graphData.data.id;
            const first_name = graphData.data.givenName;
            const last_name = graphData.data.surname;
            const email = graphData.data.mail;
            const phone_number = graphData.data.mobilePhone;
            const office_location = graphData.data.officeLocation;
            const created_at = DateTime.now().toJSDate();
            const is_active = 'true'

            const userData = await getUser(id);

            if (Object.keys(userData).length > 0)
                return userData;

            if (Object.keys(userData).length === 0){
                const user = {
                    "id": id,
                    "first_name" : first_name,
                    "last_name" : last_name,
                    "email" : email,
                    "phone_number" : phone_number,
                    "office_location" : office_location,                
                    //"created_at":12345678,
                    "is_active":is_active
                }

                await createUser(user);

                const userData = await getUser(id);

                return userData;
            }
        }
    } catch (err){
        console.log(err)
    }
}
