import React, { useState, useEffect, useContext, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { getAllPermission } from "../../services/permissionService";
import { updateRolesAndPermission } from "../../services/roleService";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "./rolesAndPermissionPermissionList.css";
import { ContextData } from "../../components/appsession";
import { ConstructionOutlined } from "@mui/icons-material";
import ProgressBar from "react-bootstrap/ProgressBar";
import { getUser } from "../../services/userService";
import errorMessageDisplay from "../../utils/errorMessageDisplay";
import { toast } from "react-toastify";

const RolesAndPermissionPermissionList = ({
  role,
  rolesHandler,
  accessDisabled,
}) => {
  const [userData, setuserData] = useContext(ContextData);
  const [modalShow, setModalShow] = useState(false);
  const [allPermissionsBeforeSave, setAllPermissions] = useState([]);
  const [permName, setPermName] = useState("");
  const [permDesc, setPermDesc] = useState("");
  const [updateData, setupdateData] = useState({});
  const [roleData, setRoleData] = useState({});
  const [isloading, setIsLoading] = useState(true);
  const [MainMenudata, setMainMenudata] = useState({});
  const [UserSetupdata, setUserSetupdata] = useState({});
  const [MasterFilesata, setMasterFilesata] = useState({});
  const [TankwashSetupdata, setTankwashSetupdata] = useState({});
  const [DriverPaydata, setDriverPaydata] = useState({});
  const [DriverPaySettingsdata, setDriverPaySettingsdata] = useState({});
  const save = async () => {
    const allPermissionsBeforeSave = await getAllPermission();
    setAllPermissions(allPermissionsBeforeSave);
    setModalShow(false);
  };
  useEffect(() => {
    setMainMenudata(
      role.rolespermission.filter(
        (o) => o.permissiondetail.categorysequence.categoryname == "Main Menu"
      )
    );
    setUserSetupdata(
      role.rolespermission.filter(
        (o) => o.permissiondetail.categorysequence.categoryname == "User Setup"
      )
    );
    setMasterFilesata(
      role.rolespermission.filter(
        (o) =>
          o.permissiondetail.categorysequence.categoryname == "Master Files"
      )
    );
    setTankwashSetupdata(
      role.rolespermission.filter(
        (o) =>
          o.permissiondetail.categorysequence.categoryname == "Tankwash Setup"
      )
    );
    setDriverPaydata(
      role.rolespermission.filter(
        (o) =>
          o?.permissiondetail?.categorysequence?.categoryname == "Driver Pay"
      )
    );
    setDriverPaySettingsdata(
      role.rolespermission.filter(
        (o) =>
          o?.permissiondetail?.categorysequence?.categoryname == "Driver Pay settings"
      )
    );
    if (Object.keys(role).length > 0) {
      setRoleData(role);
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [Object.keys(role).length]);
  const savePermission = async (rlRole) => {
    try {
      const roleServiceReponse = await updateRolesAndPermission(updateData);
      const  userNewData=await getUser(userData.id)
      return   NotificationManager.success(
        "Permissions Updated, Application Will Reload In 15 Seconds",
        "Success",
        1500
      );
    } catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Failed to update permissions', { autoClose: false } );
      }
    }
  };
  const checkboxHandler = (e, item, roleType) => {
    let payload = {};
    let rolesAndPermissionArr = updateData?.rolespermissionsarr
      ? updateData.rolespermissionsarr
      : [];
    if (roleType === "view") {
      const element1= document.getElementById(`edit_${item.permission_id}`);
      let isEditChecked=false;
      let isViewChecked=false;
      if(!e.target.checked){
        element1.checked=false
        isEditChecked=false,
        isViewChecked=false
      }else{
        isViewChecked=true
      }
      let existingelement = rolesAndPermissionArr
        .filter((e) => e.id === item.id)
        .filter((el) => Object.keys(el).includes("can_view"));
      if (existingelement.length > 0) {
        rolesAndPermissionArr = rolesAndPermissionArr.filter(
          (el) => el.id != existingelement[0].id
        );
      
        existingelement[0].can_view = isViewChecked;
        existingelement[0].can_edit=isEditChecked

        rolesAndPermissionArr.push(existingelement[0]);
      } else {
       
        let obj = {
          id: item.id,
          role_id: item.role_id,
          can_view: isViewChecked,
          can_edit:isEditChecked
        };
        rolesAndPermissionArr.push(obj);
      }
    }
    if (roleType === "edit") {
      const element1= document.getElementById(`view_${item.permission_id}`);
      let isEditChecked=false;
      let isViewChecked=false;
      if(e.target.checked){
        element1.checked=true
        isEditChecked=true,
        isViewChecked=true

      }else{
        isEditChecked=false;
        if(isViewChecked=true){
         isViewChecked=true
        }else{
          isViewChecked=false
        }
     
      }

   
    
      let existingelement = rolesAndPermissionArr
        .filter((e) => e.id === item.id)
        .filter((el) => Object.keys(el).includes("can_edit"));
      if (existingelement.length > 0) {
        rolesAndPermissionArr = rolesAndPermissionArr.filter(
          (el) => el.id != existingelement[0].id
        );
        existingelement[0].can_edit = isEditChecked;
        existingelement[0].can_view = isViewChecked;
        rolesAndPermissionArr.push(existingelement[0]);
        // }
      } else {
        let obj = {
          id: item.id,
          role_id: item.role_id,
          can_edit:  isEditChecked,
          can_view: isViewChecked,
        };
        rolesAndPermissionArr.push(obj);
      }
    }
    if (roleType === "rolesActive") {
      payload.role_id = item.id;
      payload.is_active = e.target.checked;
    }
    payload.rolespermissionsarr = rolesAndPermissionArr;
    setupdateData(payload);
  };
  return (
    <>
      <NotificationContainer />
      <div className="row mt_30">
        <div className="col-xl-12">
          <div className="card card_shadow">
            <div className="card-body ">
              <div className="permissionchklistbutton">
                {userData.roles.rolespermission?.filter(
                  (i) =>
                    i.permissiondetail.code === "Roles" && i.can_edit === false
                ).length <= 0 ? (
                  <button
                    type="button"
                    className="btn_blue btn-blue"
                    onClick={() => savePermission(role)}
                  >
                    SAVE
                  </button>
                ) : (
                  <button type="button" className="btn btn-secondary">
                    SAVE
                  </button>
                )}
              </div>
              {isloading ? (
                <div>
                  <ProgressBar animated now={100} />
                </div>
              ) : (
                <div>
                  <div className="table_header_section">
                    <div className="table_header">
                      Permission Checklist ({role.name})
                    </div>
                    <div className="roleIsActive">
                      <input
                        type="checkbox"
                        id="roleIsActive"
                        disabled={
                          userData.roles.rolespermission?.filter(
                            (i) =>
                              i.permissiondetail.code === "Roles" &&
                              i.can_edit === false
                          ).length > 0
                            ? true
                            : false
                        }
                        defaultChecked={role.is_active}
                        onChange={(e) =>
                          checkboxHandler(e, role, "rolesActive")
                        }
                      />
                      <label className="pl_6" htmlFor="roleIsActive">
                        Active
                      </label>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-borderless mb-0">
                      <thead className="table-light othertableheader">
                        <tr>
                          <th>Permission Name</th> <th>View</th> <th>Edit</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        <>
                          <tr>
                            <td>
                              <p className="roles_indidual_header">
                                {
                                  MainMenudata[0]?.permissiondetail
                                    .categorysequence.categoryname
                                }
                              </p>
                            </td>
                          </tr>
                          {MainMenudata.map((item) => (
                            <tr id={item.id} key={item.id}>
                              <th scope="row">{item?.permissiondetail?.name}</th>
                              <td>
                                <div className={`${!item.permissiondetail.view_disabled?"form-group1":"disable_form_group"}`}>
                                  <input
                                    type="checkbox"
                                    id={"view_" + item.permission_id}
                                    defaultChecked={item.can_view}
                                    disabled={item.permissiondetail.view_disabled}
                                    onChange={(e) =>
                                      checkboxHandler(e, item, "view")
                                    }
                                  />
                                  <label
                                    htmlFor={"view_" + item.permission_id}
                                  ></label>
                                </div>
                              </td>
                              <td>
                                <div className={`${!item.permissiondetail.edit_disabled?"form-group1":"disable_form_group"}`}>
                                  <input
                                    type="checkbox"
                                    id={"edit_" + item.permission_id}
                                    disabled={item.permissiondetail.edit_disabled}
                                    defaultChecked={item.can_edit}
                                    onChange={(e) =>
                                      checkboxHandler(e, item, "edit")
                                    }
                                  />
                                  <label
                                    htmlFor={"edit_" + item.permission_id}
                                  ></label>
                                </div>
                              </td>
                            </tr>
                          ))}
                          {/*UserSetupdata*/}
                          <tr>
                            <td>
                              <p className="roles_indidual_header">
                                {
                                  UserSetupdata[0]?.permissiondetail
                                    .categorysequence.categoryname
                                }
                              </p>
                            </td>
                          </tr>
                          {UserSetupdata.map((item) => (
                            <tr id={item.id} key={item.id}>
                              <th scope="row">{item?.permissiondetail.name}</th>
                              <td>
                                <div className={`${!item.permissiondetail.view_disabled?"form-group1":"disable_form_group"}`}>
                                  <input
                                    type="checkbox"
                                    id={"view_" + item.permission_id}
                                    disabled={item.permissiondetail.view_disabled}
                                    defaultChecked={item.can_view}
                                    onChange={(e) =>
                                      checkboxHandler(e, item, "view")
                                    }
                                  />
                                  <label
                                    htmlFor={"view_" + item.permission_id}
                                  ></label>
                                </div>
                              </td>
                              <td>
                                <div className={`${!item.permissiondetail.edit_disabled?"form-group1":"disable_form_group"}`}>
                                  <input
                                    type="checkbox"
                                    id={"edit_" + item.permission_id}
                                    disabled={item.permissiondetail.edit_disabled}
                                    defaultChecked={item.can_edit}
                                    onChange={(e) =>
                                      checkboxHandler(e, item, "edit")
                                    }
                                  />
                                  <label
                                    htmlFor={"edit_" + item.permission_id}
                                  ></label>
                                </div>
                              </td>
                            </tr>
                          ))}
                          {/*MasterFilesata*/}
                          <tr>
                            <td>
                              <p className="roles_indidual_header">
                                {
                                  MasterFilesata[0]?.permissiondetail
                                    .categorysequence.categoryname
                                }
                              </p>
                            </td>
                          </tr>
                          {MasterFilesata.map((item) => (
                            <tr id={item.id} key={item.id}>
                              <th scope="row">{item?.permissiondetail.name}</th>
                              <td>
                                <div className={`${!item.permissiondetail.view_disabled?"form-group1":"disable_form_group"}`}>
                                  <input
                                    type="checkbox"
                                    id={"view_" + item.permission_id}
                                    disabled={item.permissiondetail.view_disabled}
                                    defaultChecked={item.can_view}
                                    onChange={(e) =>
                                      checkboxHandler(e, item, "view")
                                    }
                                  />
                                  <label
                                    htmlFor={"view_" + item.permission_id}
                                  ></label>
                                </div>
                              </td>
                              <td>
                                <div className={`${!item.permissiondetail.edit_disabled?"form-group1":"disable_form_group"}`}>
                                  <input
                                    type="checkbox"
                                    id={"edit_" + item.permission_id}
                                    disabled={item.permissiondetail.edit_disabled}
                                    defaultChecked={item.can_edit}
                                    onChange={(e) =>
                                      checkboxHandler(e, item, "edit")
                                    }
                                  />
                                  <label
                                    htmlFor={"edit_" + item.permission_id}
                                  ></label>
                                </div>
                              </td>
                            </tr>
                          ))}
                          {/*TankwashSetupdata*/}
                          <tr>
                            <td>
                              <p className="roles_indidual_header">
                                {
                                  TankwashSetupdata[0]?.permissiondetail
                                    .categorysequence.categoryname
                                }
                              </p>
                            </td>
                          </tr>
                          {TankwashSetupdata.map((item) => (
                            <tr id={item.id} key={item.id}>
                              <th scope="row">{item?.permissiondetail.name}</th>
                              <td>
                                <div className={`${!item.permissiondetail.view_disabled?"form-group1":"disable_form_group"}`}>
                                  <input
                                    type="checkbox"
                                    id={"view_" + item.permission_id}
                                    disabled={item.permissiondetail.view_disabled}
                                    defaultChecked={item.can_view}
                                    onChange={(e) =>
                                      checkboxHandler(e, item, "view")
                                    }
                                  />
                                  <label
                                    htmlFor={"view_" + item.permission_id}
                                  ></label>
                                </div>
                              </td>
                              <td>
                                <div className={`${!item.permissiondetail.edit_disabled?"form-group1":"disable_form_group"}`}>
                                  <input
                                    type="checkbox"
                                    id={"edit_" + item.permission_id}
                                    disabled={item.permissiondetail.edit_disabled}
                                    defaultChecked={item.can_edit}
                                    onChange={(e) =>
                                      checkboxHandler(e, item, "edit")
                                    }
                                  />
                                  <label
                                    htmlFor={"edit_" + item.permission_id}
                                  ></label>
                                </div>
                              </td>
                            </tr>
                          ))}
                          {/*DriverPaydata*/}
                          <tr>
                            <td>
                              <p className="roles_indidual_header">
                                {
                                  DriverPaydata[0]?.permissiondetail
                                    .categorysequence.categoryname
                                }
                              </p>
                            </td>
                          </tr>
                          {DriverPaydata.map((item) => (
                            <tr id={item.id} key={item.id}>
                              <th scope="row">{item?.permissiondetail.name}</th>
                              <td>
                                <div className={`${!item.permissiondetail.view_disabled?"form-group1":"disable_form_group"}`}>
                                  <input
                                    type="checkbox"
                                    id={"view_" + item.permission_id}

                                    defaultChecked={item.can_view}
                                    disabled={item.permissiondetail.view_disabled}
                                    onChange={(e) =>
                                      checkboxHandler(e, item, "view")
                                    }
                                   
                                     />
                                  <label
                                   htmlFor={"view_" + item.permission_id}
                                  ></label>
                                </div>
                              </td>
                              <td>
                                <div className={`${!item.permissiondetail.edit_disabled?"form-group1":"disable_form_group"}`}>
                                  <input
                                    type="checkbox"
                                    id={"edit_" + item.permission_id}
                                    disabled={item.permissiondetail.edit_disabled}
                                    defaultChecked={item.can_edit}
                                    onChange={(e) =>
                                      checkboxHandler(e, item, "edit")
                                    }
                                  />
                                  <label
                                    htmlFor={"edit_" + item.permission_id}
                                  ></label>
                                </div>
                              </td>
                            </tr>
                          ))}
                          {/*DriverPaysettingdata*/}
                          <tr>
                            <td>
                              <p className="roles_indidual_header">
                                {
                                  DriverPaySettingsdata[0]?.permissiondetail
                                    .categorysequence.categoryname
                                }
                              </p>
                            </td>
                          </tr>
                          {DriverPaySettingsdata.map((item) => (
                            <tr id={item.id} key={item.id}>
                              <th scope="row">{item?.permissiondetail.name}</th>
                              <td>
                                <div className={`${!item.permissiondetail.view_disabled?"form-group1":"disable_form_group"}`}>
                                  <input
                                    type="checkbox"
                                    id={"view_" + item.permission_id}
                                    defaultChecked={item.can_view}
                                    onChange={(e) =>
                                      checkboxHandler(e, item, "view")
                                    }
                                    disabled={item.permissiondetail.view_disabled}
                                  />
                                  <label
                                    htmlFor={"view_" + item.permission_id}
                                  ></label>
                                </div>
                              </td>
                              <td>
                                <div className={`${!item.permissiondetail.edit_disabled?"form-group1":"disable_form_group"}`}>
                                  <input
                                    type="checkbox"
                                    id={"edit_" + item.permission_id}
                                    onChange={(e) =>
                                      checkboxHandler(e, item, "edit")
                                    }
                                    defaultChecked={item.can_edit}
                                    disabled={item.permissiondetail.edit_disabled}
                                  />
                                  <label
                                    htmlFor={"edit_" + item.permission_id}
                                  ></label>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            PERMISSION CREATION
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label for="exampleFormControlInput1">PERMISSION NAME</label>
            <input
              type="text"
              className="form-control"
              id="txtPermissionName"
              onInput={(event) => setPermName(event.target.value)}
              placeholder="e.g. Terminal Planner"
            />
            <label for="exampleFormControlInput1">PERMISSION DESCRIPTION</label>
            <input
              type="text"
              className="form-control"
              id="txtPermissionDesc"
              onInput={(event) => setPermDesc(event.target.value)}
              placeholder="e.g. Terminal Planner"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => setModalShow(false)}>Close</Button>
          <Button onClick={(e) => save()}>Save</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default RolesAndPermissionPermissionList;
