import { useState, useEffect, useRef, useContext } from "react";
import {
  getOverridesbyoverridelistid, updateDriverSchedule, addDriverSchedule,
  getDriverSchedule, deleteoverrideschedulebyid, getSchedulePreferencesbyId,
  getoverridescheduledriverid, updateSchedulePreferences, addDriverSchedulePreferences
} from "../../services/driverService";
import { getAllDataByGenericAPI } from "../../services/genericService";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { DateTime } from "luxon";
import { ContextData } from "../appsession";
import Modal from "react-bootstrap/Modal";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import OverrideSchedule from "../../components/overrideSchedule/overrideSchedule";
import OverrideUpdateSchedule from "../../components/overrideUpdateSchedule/OverrideUpdateSchedule"
import { process } from "@progress/kendo-data-query";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import "./driverBodyForSchedule.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import { toast } from "react-toastify";
import errorMessageDisplay from "../../utils/errorMessageDisplay";

gsap.registerPlugin(Draggable);


const DriverBodyForSchedule = (props) => {
  const { driver, accessDisabled } = props;
  const [userData, setuserData] = useContext(ContextData);
  const unavailableBoxRef = useRef();
  const availableBoxRef = useRef();
  const scheduleContainerRef = useRef();
  const scheduleContainerGridRef = useRef();
  const [gridWidth, setGridWidth] = useState();
  const [gridHeight, setGridHeight] = useState()
  const [scheduleGrid, setScheduleGrid] = useState();
  const [scheduleData, setScheduleData] = useState([]);
  const [daysOfWeek, setDaysOfWeek] = useState(["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]);
  const [daysArr, setDaysArr] = useState([
    {
      day: "Sun" || "Sunday",
      nights_out: "",
    },
    {
      day: "Mon" || "Monday",
      nights_out: ""
    },
    {
      day: "Tue" || "Tuesday",
      nights_out: ""
    }, {
      day: "Wed" || "Wednesday",
      nights_out: ""
    }, {
      day: "Thu" || "Thursday",
      nights_out: ""
    }, {
      day: "Friday" || "Fri",
      nights_out: ""
    }, {
      day: "Sat" || "Saturday",
      nights_out: ""
    }
  ]);
  const [modalShow, setModalShow] = useState(false);
  const [overrideeditdata, setoverrideeditdata] = useState([]);
  const [showeditModal, setshoweditModal] = useState(false);
  const [openOverrideModal, setOpenOverrideModal] = useState(false);
  const [alloverrideSchedule, setalloverrideSchedule] = useState([]);
  const [filledInCells, setfilledInCells] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOverrideLoading, setIsOverrideLoading] = useState(false);
  const [nightOutData, setNightOutData] = useState([]);
  const [allDriverReasonCodes, setAllDriverReasoonCodes] = useState([]);

  const gridProperties = {
    gridRows: 25,
    gridColumns: 8,
  };

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });

  const [dataResult, setDataResult] = useState(
    process(alloverrideSchedule, dataState)
  );

  const dataStateChange = (event) => {
    setDataResult(process(alloverrideSchedule, event.dataState));
    setDataState(event.dataState);
  };

  const editoverridemodal = async (override) => {
    try {
      setIsOverrideLoading(true);
      const getoverridedata = await getOverridesbyoverridelistid(override.id);
      setoverrideeditdata(getoverridedata);
      overrideAddHandler();
      setIsOverrideLoading(false);
    }
    catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Failed to edit override list', { autoClose: false } );
      }
    }
    setshoweditModal(true);
  };

  function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  };



  const Editoverridemodal = (props) => {
    return (
      <td className="adjustbutton">
        <button
          type="button"
          className="btn-blue_schedule_override ml_10"
          onClick={() => editoverridemodal(props.dataItem)}
        >
          Edit
        </button>
      </td>
    );
  };

  const EditOverrideModal = (props) => (
    <Editoverridemodal
      {...props}
      editoverridemodal={editoverridemodal}
    />
  );

  const from_DateTimeValue = (props) => {
    let from_Dates = Date.parse(props.dataItem.from_date);
    return (
      <td>
        {DateTime.fromMillis(parseInt(from_Dates))
          .toFormat("MM-dd-yyyy")
          .toString()}
      </td>
    );
  };

  const to_DateTimeValue = (props) => {
    let to_Dates = Date.parse(props.dataItem.to_date);
    return (
      <td>
        {DateTime.fromMillis(parseInt(to_Dates))
          .toFormat("MM-dd-yyyy")
          .toString()}
      </td>
    );
  };

  function allowDrop(ev) {
    ev.preventDefault();
  };

  function getBlockTypeClassName(data) {
    if (data.block_type == "Available") {
      return "availablebutton-schedule";
    }
    else if (data.block_type == "Unavailable") {
      return "unavailablebutton-schedule";
    }
  };

  //  For getting Overrides and Night Out data on initial rendering of component

  const loadDriverSchedule = async () => {
    try {
      setIsOverrideLoading(true);
      const driverSchedule = await getoverridescheduledriverid(driver.driver_id);      
      setIsOverrideLoading(false);
      const driverNightOuts = await getSchedulePreferencesbyId(driver.driver_id);
      setNightOutData(driverNightOuts);
      setDataResult(process(driverSchedule, dataState));
      setalloverrideSchedule(driverSchedule);
    }catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('There is a problem in retrieving in schedule or overide data, Please Refresh Your Screen', { autoClose: false } );
      }
    }
  }

  useEffect( () => {
    loadDriverSchedule();
  }, []);

  // For displaying Override data on Override kendo table
  useEffect(() => {
    setDataResult(process(alloverrideSchedule, dataState));
  }, [alloverrideSchedule]);

  // For handling Night Outs Data
  useEffect(() => {
    const tempdaysArr = [...daysArr];

    nightOutData.map(el => {
      tempdaysArr.map((element, index) => {
        if (element.day == el.day) {
          tempdaysArr[index].nights_out = el.nights_out;
          tempdaysArr[index].id = el.id;
          tempdaysArr[index].driver_id = el.driver_id;
        }
      })
    });

    setDaysArr(tempdaysArr);
  }, [nightOutData]);

  // For handling Night Outs
  const handleChange = (e, day) => {
    // if(e.target.value !==''){
    let tempdaysArr = [...daysArr];
    tempdaysArr = tempdaysArr.map((el, index) => {
      if (el.day === day) {
        tempdaysArr[index].nights_out = e.target.value;
        if (e.target.value !== '') {
          if (tempdaysArr[index].id) {
            const obj = {
              id: index,
              driver_id: driver.driver_id,
              nights_out: e.target.value,
              day: el.day
            }
            updateSchedulePreferences(obj)
            getSchedulePreferencesbyId(driver.driver_id);
          } else {
            const obj = {
              driver_id: driver.driver_id,
              nights_out: e.target.value,
              day: el.day,
              created_by: userData.userId
            }
            addDriverSchedulePreferences(obj)
            getSchedulePreferencesbyId(driver.driver_id);
          }
        }
        return el;

      } else {
        return el;
      }
    });
    setDaysArr(tempdaysArr);
    // }
  };

  // For removing schedules
  async function deleteSchedules(ev) {
    ev.preventDefault();

    let dragAttribute_targetId = ev.target.parentElement.id;
    let delete_grid_cell_start = parseInt(dragAttribute_targetId.split("_")[0]);

    let index = scheduleData.findIndex(x => parseInt(x.grid_cell_start_no) === delete_grid_cell_start);
    let deleteKeyForDatabase = scheduleData[index].id;

    try {
      await deleteoverrideschedulebyid(deleteKeyForDatabase);
      let splicedArray = scheduleData.filter(object => {
        return object.id !== deleteKeyForDatabase;
      });
      setScheduleData(splicedArray)
      NotificationManager.success("This Schedule is Deleted", "Success");
    } catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Failed to delete schedules', { autoClose: false } );
      }
    }
  };

  const updateOverrideModal = async (value) => {
    setOpenOverrideModal(value);
    const driverSchedule = await getoverridescheduledriverid(driver.driver_id);
    setDataResult(process(driverSchedule, dataState));
    setalloverrideSchedule(driverSchedule);
  };

  const refreshScheduleData = async () => {
    let scheduleDataArray = [];
    const driverSchedule = await getDriverSchedule(
      driver.driver_id,
    );

    for (let index in driverSchedule) {
      let scheduleObject = driverSchedule[index];
      let dayIndex = daysOfWeek.indexOf(driverSchedule[index].schedule_day);

      scheduleObject.grid_cell_start_no = ((((driverSchedule[index].start_time / 60) + 1) * 8) + dayIndex + 1);

      scheduleObject.grid_cell_end_no = (((driverSchedule[index].end_time / 60) * 8) + dayIndex + 1);
      scheduleDataArray.push(scheduleObject)
    }
    setScheduleData(scheduleDataArray)
  };

  // For adding the availability and unavailability of schedules
  const saveDriverSchedule = async (requestData) => {
    try {
      await timeout(500);
      setLoading(true)
      await addDriverSchedule(requestData)
      await refreshScheduleData();
      setLoading(false)
    }
    catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Failed to save driver schedule', { autoClose: false } );
      }
    }
  };

  // For updating the availability and unavailability of schedules
  const updateDriverScheduleFunction = async (requestData) => {
    try {
      await updateDriverSchedule(requestData)
      await refreshScheduleData()
    }catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Failed to update driver schedule', { autoClose: false } );
      }
    }
  };

  function dragElement(ev, block_type, block_position, isNew) {
    let driverPermission = userData.roles.rolespermission.find(it => it?.permissiondetail?.code === "Drivers");

    if (!driverPermission.can_edit) {
      return NotificationManager.error(
        "You are not allowed to drag schedule", Error
      );
    }
    else {
      ev.dataTransfer.setData("dragAttribute_targetId", ev.target.id);
      ev.dataTransfer.setData("dragAttribute_blockType", block_type);
      ev.dataTransfer.setData("dragAttribute_isNew", isNew);
      ev.dataTransfer.setData("dragAttribute_block_position", block_position);

      if (isNew) {
        ev.dataTransfer.dropEffect = "copy";
      }
      else {
        let grid_cell = ev.target.id.split("_")[0];
        ev.dataTransfer.dropEffect = "move";
        ev.dataTransfer.setData("dragAttribute_drag_cell", grid_cell);
      }
    }
  };

  const dropElement = (ev) => {
    ev.preventDefault();
    let scheduleDataArray = [];

    // let dragAttribute_targetId = ev.dataTransfer.getData("dragAttribute_targetId");
    let dragAttribute_blockType = ev.dataTransfer.getData("dragAttribute_blockType");
    let dragAttribute_isNew = ev.dataTransfer.getData("dragAttribute_isNew");
    let dragAttribute_block_position = ev.dataTransfer.getData("dragAttribute_block_position");

    scheduleDataArray = [...scheduleData];

    let drop_grid_cell = parseInt(ev.target.id.split("_")[0]);
    let dayIndex = (drop_grid_cell - parseInt((drop_grid_cell / gridProperties.gridColumns)) * gridProperties.gridColumns) - 1;

    if (dragAttribute_isNew === "true") {
      let allowDropBoolean = filledInCells.includes(drop_grid_cell);
      if (allowDropBoolean) {
        NotificationManager.error("You Cannot Drag Drop On An Already Existing Schedule", "Error");
      } else {
        let start_time = ((Math.floor(drop_grid_cell / gridProperties.gridColumns) - 1) * 60)
        let end_time = ((Math.ceil(drop_grid_cell / gridProperties.gridColumns)) * 60);
        let newBlock = {
          "start_time": start_time,
          "end_time": end_time,
          "block_type": dragAttribute_blockType,
          "grid_cell_start_no": drop_grid_cell,
          "grid_cell_end_no": drop_grid_cell + gridProperties.gridColumns,
          "schedule_day": daysOfWeek[dayIndex]
        }
        let saveBlockObject = {
          "start_time": start_time,
          "end_time": end_time,
          "block_type": dragAttribute_blockType,
          "duration": end_time - start_time,
          "schedule_day": daysOfWeek[dayIndex],
          "driver_id": driver.driver_id,
        }
        scheduleDataArray.push(newBlock);
        saveDriverSchedule(saveBlockObject);
        setScheduleData(scheduleDataArray);
      }
    } else if (dragAttribute_block_position == "end") {
      if (loading == false) {
        let dragAttribute_drag_cell = parseInt(ev.dataTransfer.getData("dragAttribute_drag_cell"));
        let index = scheduleDataArray.findIndex(x => parseInt(x.grid_cell_end_no) === dragAttribute_drag_cell)
        scheduleDataArray[index].grid_cell_end_no = drop_grid_cell;
        let end_time = (Math.floor(drop_grid_cell / gridProperties.gridColumns)) * 60;
        let updateScheduleBody = {};

        if (end_time <= scheduleDataArray[index].start_time) {
          NotificationManager.error("End time cannot be less than Start time", "Error");
        } else {
          updateScheduleBody = {
            "end_time": end_time,
            "duration": end_time - scheduleDataArray[index].start_time,
            "id": scheduleDataArray[index].id,
            "driver_id": driver.driver_id,
            "block_type": dragAttribute_blockType,
            "schedule_day": daysOfWeek[dayIndex],
            "updated_by": userData.userId
          }
        }

        // Check For Same Day Schedule
        let check_if_same_day = (scheduleDataArray[index].grid_cell_start_no - parseInt(scheduleDataArray[index].grid_cell_start_no / gridProperties.gridColumns) * gridProperties.gridColumns - 1);
        if (dayIndex == check_if_same_day) {
          (async () => {
            try {
              await updateDriverScheduleFunction(updateScheduleBody);
            } catch(err){
              console.log(err)
              if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
                errorMessageDisplay(err?.response?.data?.errors);
              }else{
              toast.error('There is a problem on retrieving schedule data, Please Refresh Your Screen', { autoClose: false } );
              }
            }
          })();
          setScheduleData(scheduleDataArray);
        } else {
          NotificationManager.error("You Must Drag Between The Same Day", "Error");
        }
      }
    } else if (dragAttribute_block_position == "start") {
      if (loading == false) {
        let dragAttribute_drag_cell = parseInt(ev.dataTransfer.getData("dragAttribute_drag_cell"));
        let index = scheduleDataArray.findIndex(x => parseInt(x.grid_cell_start_no) === dragAttribute_drag_cell);

        scheduleDataArray[index].grid_cell_start_no = drop_grid_cell;
        let start_time = (Math.floor(drop_grid_cell / gridProperties.gridColumns) - 1) * 60;
        let updateScheduleBody = {};

        if (start_time >= scheduleDataArray[index].end_time) {
          NotificationManager.error("Start time cannot be greater than End time", "Error");
        } else {
          updateScheduleBody = {
            "start_time": start_time,
            "duration": scheduleDataArray[index].end_time - start_time,
            "id": scheduleDataArray[index].id,
            "driver_id": driver.driver_id,
            "block_type": dragAttribute_blockType,
            "schedule_day": daysOfWeek[dayIndex],
            "updated_by": userData.userId
          }
        }

        let check_if_same_day = (scheduleDataArray[index].grid_cell_end_no - parseInt(scheduleDataArray[index].grid_cell_end_no / gridProperties.gridColumns) * gridProperties.gridColumns - 1);
        if (dayIndex == check_if_same_day) {
          (async () => {
            try {
              await updateDriverScheduleFunction(updateScheduleBody);
            } catch(err){
              console.log(err)
              if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
                errorMessageDisplay(err?.response?.data?.errors);
              }else{
              toast.error('You Must Drag Between The Same Day', { autoClose: false } );
              }
            }
          })();

          setScheduleData(scheduleDataArray);
        } else {
          NotificationManager.error("You Must Drag Between The Same Day", "Error");
        }
      }
    }
  };
 // It will run when Available and Unavailability will be chnaged on Time Schedule Grid
  
  const colorGridCells = () => {
    if (scheduleData) {
      // Clear all divs from draw
      for (let i = 0; i < gridProperties.gridRows * gridProperties.gridColumns; i++) {
        try {
          document.getElementById(i + "_gridcell").style.backgroundColor = "white";
        } catch (err) {
          if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
            errorMessageDisplay(err?.response?.data?.errors);
          }else{
          console.error('Error occur:',err );
          }
        }
      }

      let filledCellArray = [];

      for (let index in scheduleData) {
        // Color all middle cells between available and drop
        let start_of_paint = scheduleData[index].grid_cell_start_no;
        let end_of_paint = scheduleData[index].grid_cell_end_no;

        let dayIndex =
        end_of_paint -
        parseInt(end_of_paint / gridProperties.gridColumns) *
        gridProperties.gridColumns -
        1;
       //Color the Extension Cells
       for (let i = start_of_paint; i <= end_of_paint; i++) {
        let check_if_same_day =
          i -
          parseInt(i / gridProperties.gridColumns) *
          gridProperties.gridColumns -
          1;

        if (dayIndex == check_if_same_day) {
          //Push Into The Validation Array If There Is a Match
          filledCellArray.push(i);
          if (scheduleData[index].block_type == "Available") {
            document.getElementById(i + "_gridcell").style.backgroundColor =
              "#77d6a2";
          } else if (scheduleData[index].block_type == "Unavailable") {
            document.getElementById(i + "_gridcell").style.backgroundColor =
              "#fa737c";
            }
          }
        }
      }
      setfilledInCells(filledCellArray);
    }
  };

  useEffect(() => {
    colorGridCells();
  }, [scheduleData, loading]); 


  // For setting the width and height of Time Schedule grid
  const initializeGrid = async () => {
    let gridArray = [];
    let gridWidth = window.innerWidth / gridProperties.gridColumns;
    setGridWidth(gridWidth);
    setGridHeight(30);
    let gridHeight = 30;
    let x, y;
    for (let i = 0; i < gridProperties.gridRows * gridProperties.gridColumns; i++) {
      y = Math.floor(i / gridProperties.gridColumns) * gridHeight;
      x = (i * gridWidth) % (gridProperties.gridColumns * gridWidth);
      gridArray.push({
        i: i,
        x: x,
        y: y
      });
    }
    setScheduleGrid(gridArray);
    await refreshScheduleData();
  };

  useEffect(() => {
    initializeGrid();
  }, []); 



  const overrideAddHandler = async function () {
    try {       
      // I am using here, generic API for getting 
      const payload = [{ domain_name: "DRIVER_REASON_CODES" }];
      const data = await getAllDataByGenericAPI('lookups', payload);
      setAllDriverReasoonCodes(data);
    } catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('There is a problem in retrieving driver reason codes', { autoClose: false } );
      }
    }
  };

  return (
    <>
      <NotificationContainer />
      <div className="row mt_30">
        <div className="col-xl-12">
          <div className="card card_shadow">
            <div className="card-body" ref={scheduleContainerRef}>
              <div className="table_header_section">
                <div className="table_header">Schedule Details</div>
              </div>
              <div className="schedule_information_text">
                To set a drivers Available and/or Unavailable time you simply
                drag either the green or red box and drop it at the desired
                start time for the desired day of the week. You can then adjust
                the time by dragging either the start or end time up or down.
              </div>
              <div className="arrowcalender">
                <div className="indicator_wrapper">
                  <div className="indicator_divide">
                    <div className="planner_date_text1">Weekly Schedule</div>
                    <div className="indicator_sec">
                      <div
                        id="available"
                        draggable="true"
                        onDragStart={(e) => dragElement(e, "Available", "header", true)}
                        className="indicator_inner ml_20"
                      >
                        <div
                          className="available_box"
                          ref={availableBoxRef}
                        ></div>
                        <div className="indicator_text">Available</div>
                      </div>
                      <div
                        id="unavailable"
                        draggable="true"
                        onDragStart={(e) => dragElement(e, "Unavailable", "header", true)}
                        className="indicator_inner ml_20">
                        <div
                          className="unavailable_box"
                          ref={unavailableBoxRef}
                        ></div>
                        <div className="indicator_text">Unavailable</div>
                      </div>
                    </div>
                  </div>

                  <button
                    type="button"
                    className="btn-blue_schedule"
                    disabled={!accessDisabled}
                    onClick={() => {
                      setModalShow(true);
                    }}
                  >
                    Overrides
                  </button>
                </div>
              </div>
              <div className="night_out_sec_main">
                <div className="night_out_individual_left_text">Night Outs</div>
                {/* @audit-ok Dropdown */}
                {daysArr &&
                  daysArr.length &&
                  daysArr.map((el, index) => (
                    <div className="night_out_individual" key={el.id}>
                      <select
                        className="d-block  form-control mt-4 mx-auto"
                        onChange={(e) => handleChange(e, el.day)}
                        value={daysArr[index].nights_out}
                        id="variation-select"
                      >
                        <option value={''} selected disabled>Select Y/N</option>
                        <option value={false || "false"}>No</option>
                        <option value={true || "true"}>Yes</option>
                      </select>
                    </div>
                  ))}
              </div>
              <div
                className="container_schedule"
                ref={scheduleContainerGridRef}
              >
                {scheduleGrid
                  ? scheduleGrid.map((gridElement) =>
                    gridElement.i < 8 ? (
                      <>
                        <div
                          id={gridElement.i + "_topHeaderCell"}
                          className="schedule-grid"
                          style={{
                            top: gridElement.y,
                            left: gridElement.x,
                            width: gridWidth - 1,
                            height: gridHeight - 1,
                          }}
                        >
                          {gridElement.i === 0 ? "Schedule" :
                            (
                              //-2 becuase World Weekdays start on Monday , and we want to start on Sunday and there are 8 elements
                              daysOfWeek[gridElement.i - 1]
                            )
                          }
                        </div>
                      </>
                    ) : gridElement.i % 8 === 0 ? (
                      <div
                        id={gridElement.i + "_gridcell"}
                        className="schedule-grid"
                        style={{
                          top: gridElement.y,
                          left: gridElement.x,
                          width: gridWidth - 1,
                          height: gridHeight - 1,
                        }}
                      >
                        { //Gridelement is genererated from I , there is a minus 1 as the number of rows are 25 and not 24
                          gridElement.i / 8 - 1 + ":00"
                        }

                      </div>
                    ) : (
                      <>
                        <div
                          id={gridElement.i + "_gridcell"}
                          key={gridElement.i + "_gridcell"}
                          className="schedule-grid"
                          style={{
                            top: gridElement.y,
                            left: gridElement.x,
                            width: gridWidth - 1,
                            height: gridHeight - 1,
                          }}
                          onDrop={(e) => dropElement(e)}
                          onDragOver={(e) => allowDrop(e)}
                        >
                          {scheduleData.length > 0 ? (
                            scheduleData.map((data) =>
                              data.grid_cell_start_no === gridElement.i ? (
                                <>
                                  {loading ? (<div className={getBlockTypeClassName(data)}>{data.block_type == "Available" ? (<ProgressBar animated variant="success" now={100} />) : (<ProgressBar animated variant="danger" now={100} />)}</div>
                                  ) : (
                                    <div className={getBlockTypeClassName(data)}
                                      draggable="true"
                                      onDragStart={(e) => dragElement(e, data.block_type, "start", false)}
                                      id={`${data.grid_cell_start_no}_gridData`}
                                      key={data.grid_cell_start_no + "_gridData"}>{data.block_type}<div className="close_avail_unavail" onClick={(e) => deleteSchedules(e)}>X</div></div>
                                  )
                                  }
                                </>
                              )
                                : null
                            )
                          ) : null}
                          {scheduleData.length > 0 ? (
                            scheduleData.map((data) =>
                              data.grid_cell_end_no === gridElement.i ? (
                                <>
                                  {loading ? (<div className={getBlockTypeClassName(data)}>{data.block_type == "Available" ? (<ProgressBar animated variant="success" now={100} />) : (<ProgressBar animated variant="danger" now={100} />)}</div>
                                  ) : (<div className={getBlockTypeClassName(data)}
                                    draggable="true"
                                    onDragStart={(e) => dragElement(e, data.block_type, "end", false)}
                                    id={`${data.grid_cell_end_no}_gridData`}
                                    key={data.grid_cell_end_no + "_gridData"}>End</div>)}
                                </>
                              )
                                : null
                            )
                          ) : null}
                        </div>
                      </>
                    )
                  )
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal for kendotable */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="override_modal_header_close override_head_set_close" closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="override_modal_header override_head_set">
            <Modal.Title id="contained-modal-title-vcenter" className="header-title1">
              OVERRIDES
            </Modal.Title>
            <button
              type="button"
              className="btn_blue btn-blue_schedule_override"
              onClick={() => {
                setOpenOverrideModal(true);
                overrideAddHandler();
              }}
              disabled={!accessDisabled}
            >
              ADD
            </button>
          </div>
          <div className="form-group">
            {
              alloverrideSchedule?.length > 0 && !isOverrideLoading ? (
                <Grid
                  filter={dataState.filter}
                  filterable={true}
                  sort={dataState.sort}
                  sortable={true}
                  pageable={{
                    buttonCount: 10,
                    info: true,
                    previousNext: true,
                    pageSizes: true,
                  }}
                  resizable={true}
                  skip={dataState.skip}
                  take={dataState.take}
                  data={dataResult}
                  onDataStateChange={dataStateChange}
                >
                  <GridColumn cell={from_DateTimeValue}
                    title="From Date"
                    width="300px"
                  />
                  <GridColumn cell={to_DateTimeValue}
                    title="To Date"
                    width="300px"
                  />
                  <GridColumn title="Action" cell={EditOverrideModal} />
                </Grid>
              ) : isOverrideLoading ? (
                <ProgressBar animated now={100} />
              ) :
                (
                  <div className="text-center">No data found</div>
                )
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      {/* modal for add */}
      <Modal
        show={openOverrideModal}
        onHide={() => {
          setOpenOverrideModal(false);
          setAllDriverReasoonCodes([]);
        }}
        dialogClassName="driver_schedule_modal driver_schedule_modal_width"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="header-title1">Add Override</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OverrideSchedule driver={driver} updateOverrideModal={updateOverrideModal} allDriverReasonCodes={allDriverReasonCodes} />
        </Modal.Body>
      </Modal>

      {/* modal for edit */}
      <Modal
        show={showeditModal}
        onHide={() => setshoweditModal(false)}
        dialogClassName="driver_schedule_modal driver_schedule_modal_width"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="header-title1">Edit Override</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OverrideUpdateSchedule driver={driver} updateOverrideModal={updateOverrideModal} overrideeditdata={overrideeditdata} allDriverReasonCodes={allDriverReasonCodes} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DriverBodyForSchedule;
