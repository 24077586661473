import { useCallback, useContext, useEffect, useState,useRef } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { searchDriverpayKendo,UpdateDriverPayByDistanceExceptionLaneOverride } from "../../services/driverPayServer";
import ProgressBar from "react-bootstrap/ProgressBar";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { DateTime } from "luxon";
import { ContextData } from "../../components/appsession";
import { ToastContainer, toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import debounce from 'lodash/debounce';
import filterOperators from '../../utils/filterOperators.js'
import AuditLogModalComponent from "./auditLogModal";
import errorMessageDisplay from "../../utils/errorMessageDisplay.js";

const DriverPayDistanceTable = (props) => {
  const { accessDisabled,isEditDisable } = props;
  const [data, setData] = useState(null);
  const[payDistance,setPayDistance]=useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [userData, setUserData] = useContext(ContextData);
  const[selectedId,setSelectedId]=useState("");

  const [takeNumberOfResult, setTakeNumberOfResult] = useState(25);
  const [skipNumberOfResult, setSkipNumberOfResult] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalResult, setTotalResult] = useState(0);
  const [searchTerms, setSearchTerms] = useState([]);
  const [sortData,setSortData] = useState([{field: "id",dir: "asc"}]);

  const[allField,setAllField]=useState({
  id:"",
  origCity:"",
  orgState:"",
  orgZip:"",
  destCity:"",
  destState:"",
  destZip:"",
  mileagePackage:""
  })
  
  const [dataState, setDataState] = useState({
    skip: skipNumberOfResult,
    take: takeNumberOfResult,
    filter: {
      logic: "and",
      filters: [],
    },
    sort:sortData,
  });
  const [dataResult, setDataResult] = useState([]);

const fieldsToField = [
    "id",
    "org_city",
    "org_state",
    "org_zip",
    "dest_city",
    "dest_state",
    "dest_zip",
    "mileage_package",
    "pay_distance",
];

 
  useEffect(() => {
    setDataResult(data);
  }, [data]);

  const dataStateChange = (event) => {
    const filters = event?.dataState?.filter?.filters || [];
    const sortTerms=event?.dataState?.sort || [];
    const terms = filters && filters.length > 0 ? filters.map(({ field, value, operator }) => ({ field, value, operator })) : [];
    const sorts= sortTerms && sortTerms.length>0 ? sortTerms.map(({ field, dir })=>({ field, dir })) : [];
    setSearchTerms(terms);
    setSortData(sorts);
    const take = event?.dataState?.take;
    const skip = event?.dataState?.skip;
    const page = Math.floor(skip / take) + 1;
    setTakeNumberOfResult(take);
    setSkipNumberOfResult(skip);
    setPageNumber(page);
    setDataState(event.dataState);
  };

  // this useeffect will run on adding something in filter on removing in filter
  useEffect(() => {
    // it will run when there is no filter in kendo
    if (searchTerms.length <= 0 && sortData.length<=0) {
        let newsearchTerms = [];
        let newSort=[];
        debouncedSearch(newsearchTerms, newSort, pageNumber, takeNumberOfResult);
    }else if (searchTerms.length <= 0 && sortData.length>0) {
      let newsearchTerms = [];
      debouncedSearch(newsearchTerms, sortData, pageNumber, takeNumberOfResult);
  }

    // it will run when there is filter in kendo
    if (searchTerms.length > 0) {

        let searchTerm;
        // remove null undefined values of fields
        searchTerm = searchTerms.filter(obj => {
            if (
                fieldsToField.includes(obj.field) &&
                (obj.value === null || obj.value === undefined || obj.value === "")
            ) {
                return false; // remove the object
            }
            return true; // keep the object
        });
        
        setDataState({
            skip: skipNumberOfResult,
            take: takeNumberOfResult,
            filter: {
                logic: "and",
                filters: searchTerm
            },
            sort: sortData,
        })
        debouncedSearch(searchTerm, sortData, pageNumber, takeNumberOfResult);
    }

}, [searchTerms, sortData,pageNumber, takeNumberOfResult]);


 const debouncedSearch = useCallback(
  debounce(async (searchTerms, sortData, page, size) => {
       let arr=searchTerms?.map((el)=>{
        let obj={};
        obj[el?.field]=el?.value
        obj.operator=el?.operator;
        return obj
       })
       let tableName="driver_pay_distance"
       const search = await searchDriverpayKendo(tableName,arr, sortData, page, size,);
      if (search.data.length <= 0) {
          setData(search.data);
          setTotalResult(Number(search?.count[0]?.count));
      }
      if (search.data.length > 0) {
          setData(search.data);
      }
      setTotalResult(Number(search?.count[0]?.count));
  }, 1000),
  []
);

// this function will run when we want to close edit model
  const handleEditClose = () => {
    setIsEditModalOpen(false);
    setPayDistance("");
    setSelectedId("");
  };

  const rowClickHandler=(item)=>{
  setIsEditModalOpen(true);
  setSelectedId(item.id);
  setPayDistance(item.pay_distance);
  let obj={...allField}
  obj.origCity=item?.org_city;
  obj.orgState=item?.org_state;
  obj.orgZip=item?.org_zip;
  obj.destCity=item?.dest_city;
  obj.destState=item?.dest_state;
  obj.destZip=item?.dest_zip;
  obj.id=item?.id;
  obj.mileagePackage=item?.mileage_package;
  setAllField(obj)
  }
  
  // when we edit or add this function will fetch all latest data
  const handleRefresh=()=>{
    debouncedSearch(dataState?.filter?.filters ?? [], dataState.sort ?? [], pageNumber, takeNumberOfResult);
  }
  // this function will run when we edit fields
  const editDriverPayConfigHandler=async()=>{
    const payload={
        pay_distance:Number(payDistance),
        updated_by: userData?.email.split('@')[0]
  }
  if(payload.pay_distance || payload.pay_distance==0){
   let  tabname = "driver_pay_distance"
    try {
      const response = await UpdateDriverPayByDistanceExceptionLaneOverride(tabname,payload,selectedId);
      if (response.status === 200) {
        toast.success("Driver pay distance has been updated successfully.", "Success");
        setIsEditModalOpen(false);
        setPayDistance("");
        setSelectedId("");
        handleRefresh()
      } else {
        toast.error("An error occurred while updating the driver pay distance.", "Error");
      }
    }catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Failed to edit driver pay config', { autoClose: false } );
      }
    }
  } else {
    toast.error("Please fill pay distance.", "Error");
  }
}

const auditLogComponentRef = useRef();

  // Audit log modal open handler
  const auditLogModalOpenHandler = (props) => {
    // Access the child component's function using the ref
    auditLogComponentRef.current.fetchAllAuditLogData(props.id, "driver_pay_distance");
  };

  
  return (
    <>
    <ToastContainer
        position='top-right'
        theme='colored'
        autoClose={1000}
      />

    <div className='row special_row_flex'>
      <div className='col-xl-12'>
        <div className='card card_shadow'>
          <div className='card-body'>
            <div className='table-responsive'>
              {data?.length >= 0 ?
                <Grid
                   filter={dataState.filter}
                   filterable={true}
                   filterOperators={filterOperators}
                   expandField="expanded"
                   sort={dataState.sort}
                   sortable={true}
                   pageable={{
                    pageSizes: [25, 50, 100],
                    info: true,
                    previousNext: true,
                    buttonCount: 10,
                   }}
                  resizable={true}
                  skip={dataState.skip}
                  take={dataState.take}
                  data={dataResult}
                  onDataStateChange={dataStateChange}
                  onRowClick={(e) => rowClickHandler(e.dataItem)}
                  total={totalResult}
                >
                  <GridColumn
                    field='id'
                    title='ID'
                    filter='numeric'
                    filterable={true}
                    width='200px'
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.id
                            ? Number(e.dataItem.id)
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='org_city'
                    title='Origin City'
                    filterable={true}
                    width='200px'
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.org_city
                            ? e.dataItem.org_city
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='org_state'
                    title='Origin State'
                    filterable={true}
                    width='150px'
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.org_state
                            ? e.dataItem.org_state
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='org_zip'
                    title='Origin Zip'
                    filterable={true}
                    width='150px'
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.org_zip
                            ? e.dataItem.org_zip
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='dest_city'
                    title='Destination City'
                    width='200px'
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.dest_city
                            ? e.dataItem.dest_city
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='dest_state'
                    title='Destination State'
                    width='150px'
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.dest_state
                            ? e.dataItem.dest_state
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='dest_zip'
                    title='Destination Zip'
                    width='150px'
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.dest_zip
                            ? e.dataItem.dest_zip
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='pay_distance'
                    title='Pay Distance'
                    width='150px'
                    filter='numeric'
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.pay_distance
                            ? Number(e.dataItem.pay_distance)
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='mileage_package'
                    title='Mileage Package'
                    width='150px'
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.mileage_package
                            ? e.dataItem.mileage_package
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                         field='Action'
                         title='Action'
                         width='150px'
                         cell={(e) => {
                           return(
                             <td>
                               <button
                                 type="button"
                                 className="btn-blue_schedule "
                                 onClick={(i) => auditLogModalOpenHandler(e.dataItem)}
                               >
                                 Audit Log
                               </button>
                             </td>
                           );
                         }}
                        />

                </Grid> :
                <div>
                  <ProgressBar animated now={100} />
                  <div className='middle loader--text1'> </div>
                </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
    <AuditLogModalComponent ref={auditLogComponentRef} />


    {/* Edit Driver Pay Distance*/}
    <Modal
        show={isEditModalOpen}
        onHide={handleEditClose}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Edit Driver Pay Distance
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mt_10'>
              <label htmlFor='tankwashcode'>ID</label>
              <div className='meterial_autocomplete'>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={allField?.id}
                  type='text'
                  name='minutes'
                  size='small'
                  disabled={true}
                />
              </div>
          </div>
          <div className='mt_10'>
              <label htmlFor='tankwashcode'>Origin City</label>
              <div className='meterial_autocomplete'>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={allField?.origCity}
                  type='text'
                  name='minutes'
                  size='small'
                  disabled={true}
                />
              </div>
          </div>
          <div className='mt_10'>
              <label htmlFor='tankwashcode'>Origin State</label>
              <div className='meterial_autocomplete'>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={allField?.orgState}
                  type='text'
                  name='minutes'
                  size='small'
                  disabled={true}
                />
              </div>
          </div>
          <div className='mt_10'>
              <label htmlFor='tankwashcode'>Origin Zip</label>
              <div className='meterial_autocomplete'>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={allField?.orgZip}
                  type='text'
                  name='minutes'
                  size='small'
                  disabled={true}
                />
              </div>
          </div>
          <div className='mt_10'>
              <label htmlFor='tankwashcode'>Destination City</label>
              <div className='meterial_autocomplete'>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={allField?.destCity}
                  type='text'
                  name='minutes'
                  size='small'
                  disabled={true}
                />
              </div>
          </div>
          <div className='mt_10'>
              <label htmlFor='tankwashcode'>Destination State</label>
              <div className='meterial_autocomplete'>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={allField?.destState}
                  type='text'
                  name='minutes'
                  size='small'
                  disabled={true}
                />
              </div>
          </div>
          <div className='mt_10'>
              <label htmlFor='tankwashcode'>Destination Zip</label>
              <div className='meterial_autocomplete'>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={allField?.destZip}
                  type='text'
                  name='minutes'
                  size='small'
                  disabled={true}
                />
              </div>
          </div>
          {
            allField?.mileagePackage ? <>
            <div className='mt_10'>
              <label htmlFor='tankwashcode'>Mileage Package</label>
              <div className='meterial_autocomplete'>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={allField?.mileagePackage}
                  type='text'
                  name='minutes'
                  size='small'
                  disabled={true}
                />
              </div>
          </div>
            </> :<></>
          }
          <div className='mt_10'>
              <label htmlFor='tankwashcode'>Pay Distance</label>
              <div className='meterial_autocomplete'>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={payDistance}
                  type='number'
                  name='minutes'
                  placeholder='Enter Pay Distance'
                  size='small'
                  onChange={(e) => { setPayDistance(e.target.value) }}
                  disabled={isEditDisable}
                />
              </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleEditClose}>Close</Button>
          <Button
            type='submit'
             onClick={editDriverPayConfigHandler}
            disabled={isEditDisable}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DriverPayDistanceTable