import React, { useState, useEffect } from "react";
import {getallpuser} from "../../services/userService";

import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";

import ProgressBar from "react-bootstrap/ProgressBar";

const terminalBodyForUser = (props) => {
  const { terminal, userData} = props;
  const [allUser, setallUser] = useState([]);
  const [isPlanner, setIsPlanner] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 15,
    filter: {
      logic: "and",
      filters: [
        // {
        //   field: "isActive",
        //   operator: "eq",
        //   value: true,
        // },
      ],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });
  const [dataResult, setDataResult] = useState(process(allUser, dataState));

  const dataStateChange = (event) => {
    setDataResult(process(allUser, event.dataState));
    setDataState(event.dataState);
  };

  useEffect(() => {
    setDataResult(process(allUser, dataState));
  }, [allUser]);


  const fetchData = async () => {
    setLoading(true);
    // userService
      getallpuser()
      .then(users =>{
        let newData = [];
        users.forEach(user => {
          let temp = user;
          let fullName = `${temp.first_name} ${temp.last_name}`;
          temp.fullname = fullName;
          newData.push(temp)
        })
        setallUser(newData);
        setLoading(false);
        props.parentCallBackFromTerminalBodyForUser(newData);
      });
  }
  useEffect( () => {
    fetchData();
  }, []);

  return (
    <div className="row special_row_flex">
      <div className="col-xl-12">
        <div className="card card_shadow">
          <div className="card-body">
            <div className="table_header_section w-96">
              <h2 className="header-title">Users</h2>
            </div>
            {allUser?.length > 0 ? (
              <Grid
                filter={dataState.filter}
                filterable={true}
                sort={dataState.sort}
                sortable={true}
                pageable={{
                  buttonCount: 10,
                  info: true,
                  previousNext: true,
                  pageSizes: true,
                }}
                resizable={true}
                skip={dataState.skip}
                take={dataState.take}
                data={dataResult}
                onDataStateChange={dataStateChange}
              >
                <GridColumn
                  field="fullname"
                  title="Name"
                  //width="300px"
                  filterable={true}
                  cell={(e) => {
                    return (
                      <td>
                        {e.dataItem.fullname ?? ""}
                      </td>
                    );
                  }}
                />
                <GridColumn
                  field="email"
                  title="Email"
                  //width="400px"
                  filterable={true}
                  cell={(e) => {
                    return (
                      <td>{e.dataItem.email ?? ""}</td>
                    );
                  }}
                />
                <GridColumn
                  field="phone_number"
                  title="Phone"
                  //width="300px"
                  filterable={true}
                  cell={(e) => {
                    return (
                      <td>{e.dataItem.phone_number ?? ""}</td>
                    );
                  }}
                />
                <GridColumn
                  field="roles.name"
                  title="Role"
                  //width="300px"
                  filterable={true}
                  cell={(e) => {
                    return (
                      <td>
                        {e.dataItem.roles?.name ?? ""}
                      </td>
                    );
                  }}
                />
              </Grid>
            ) : loading ? (
              <div>
                <ProgressBar animated now={100} />
                <div className="middle loader--text1"> </div>
              </div>
            ) : (
              <div>No data found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default terminalBodyForUser;
